import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import "./index.scss";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import SharedInvite from "../Invite";
import logo from "../../resources/CGC-logo-white-r.webp"

const TopHeader = ({ user_name }) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [openShareModal, setOpenShareModal] = useState(false);

  let { meetingNumber } = useParams();

  const handleOpenShared = () => {
    setOpenShareModal(!openShareModal);
  };

  return (
    <>
      <Box className="header-title-container">
        <div style={{display:"flex", flexDirection:"row", columnGap:"10px"}}>
          <img
          
              alt=""
              width="100px"
              className="my-float"
              src={logo}
            />
            <hr/>
          {user_name &&<span className="welcome-swz-banner"  >
            Welcome to {user_name && `${user_name}'s`} Zoom!
          </span>}
        </div>
          

        {!user && (
          <Box className="header-buttons-container">
            <Button
              className="start-meeting-tab"
              style={{ cursor: "pointer" }}
              onClick={handleOpenShared}
            >
              Share This Meeting
            </Button>
          </Box>
        )}
      </Box>
      <SharedInvite
        meetingID={meetingNumber}
        open={openShareModal}
        onClick={handleOpenShared}
      />
    </>
  );
};

export default TopHeader;
