import React from 'react'
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Stack from "@mui/material/Stack";
import { isMobile } from "react-device-detect";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import "./modalMeetingInfo.css"

export default function M1MeetingInfo({ onhandleNext, newMeetingObj, setNewMeetingObj, setOpen }) {

    const handleChange = (newValue) => {
        setNewMeetingObj({ ...newMeetingObj, inputDate: newValue })
    };


    return (
        <div className="content-form">
            <div>
                <span style={{
                        padding:0, 
                        fontSize:"1.5rem",
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "#202020",
                    }}>Meeting Set Up</span>
            </div>

            <div className="container-input-create">
                <span className="label-input-form" >Topic / Title</span>
                <div className="container-input-create">
                    <input
                        onChange={(e) => setNewMeetingObj({ ...newMeetingObj, topic: e.target.value })}
                        className="input-credentials"
                        defaultValue={newMeetingObj?.topic || ""}
                        placeholder="Example: Let's talk about skin care"
                    />
                </div>
            </div>
            <div className="container-input-create">
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        className="container-input-create"
                    >
                        <Stack spacing={0.5} className="container-twoflex-options">
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <span className="label-input-form" >Date</span>

                                {!isMobile ? (
                                    <DesktopDatePicker
                                        id="date-select-wk"
                                        inputFormat="MM/DD/YYYY"
                                        minDate={new Date()}
                                        value={newMeetingObj?.inputDate}
                                        className="input-credentials container-input-create"
                                        style={{padding:"8px"}}
                                        onChange={handleChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: "tt.mm.jjjj",
                                                }}
                                            />
                                        )}
                                    />
                                ) : (
                                    <MobileDatePicker
                                        inputFormat="MM/DD/YYYY"
                                        value={newMeetingObj?.inputDate}
                                        className="input-credentials container-input-create"
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                )}
                            </div>
                            
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <span className="label-input-form" >Time</span>

                                <TimePicker
                                    className="input-credentials time"
                                    value={newMeetingObj?.inputDate}
                                    onChange={handleChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </div>
                        </Stack>
                    </LocalizationProvider>
                </div>
            <div className="container-input-create">
                <div>
                    <Typography variant="h6" fontWeight="bold">
                        Description
                    </Typography>
                </div>
                <div>
                    <textarea
                        type="text"
                        onChange={(e) => setNewMeetingObj({ ...newMeetingObj, description: e.target.value })}
                        className="input-credentials input-description new-text-area"
                        defaultValue={newMeetingObj?.description || ""}
                        placeholder="Example: How to improve your night time skin care regime and more!"
                    />
                </div>
            </div>
            <div className="step-btn-content">
                <button
                    className="btn-personalized-cancel"
                    onClick={(e) => {
                        setOpen(false);
                    }}
                >
                    Cancel
                </button>
                <button
                    className="btn-personalized"
                    onClick={(e) => {
                        e.preventDefault()
                        onhandleNext();
                        /* if (
                          user["http://locahost:5000/roles"]?.includes("BASIC") ||
                          user["http://locahost:5000/roles"]?.includes("VENTURE")
                        ) {
                          setOpen(false);
                          setModalReview(true);
                         
                        } else {
                          setOpenModal(false);
                          setModalMiddleStep(true);
                        } */
                    }}
                    disabled={!newMeetingObj?.topic}
                >
                    Next
                </button>
            </div>
        </div>
    )
}
