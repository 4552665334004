import React, { useEffect, useState } from 'react';

/* import './index.css'; */
import "./zoom-embedded.css"
import ZoomMtgEmbedded from '@zoomus/websdk/embedded';
import { ColorRing } from "react-loader-spinner";
import { isMobile } from "react-device-detect";

function ZoomEmbedded({ userName, email, inputEmail, idMeeting }) {
  const [lauchMeeting, setLaunchMeeting] = useState(true);
  const [user, setuser] = useState({})
  const client = ZoomMtgEmbedded.createClient();

  var authEndpoint = 'https://newsignature.herokuapp.com/'
  var sdkKey = process.env.REACT_APP_SDK_KEY
  var meetingNumber = idMeeting;
  var passWord = "";
  var role = 0;
  var userName = userName;
  var userEmail = email;
  var registrantToken = "";
  var zakToken = "";
  var leaveUrl = window.location.origin;

  useEffect(() => {
    /*     document.getElementById("start-my-meeting-swz").style.display = "block";
     */  setTimeout(function () {
    let startMtBtn = document.getElementById("start-my-meeting-swz");
    if (startMtBtn) {
      startMtBtn.click();
    }
    setLaunchMeeting(false);
  }, 2000);
    console.log("SharedArrayBuffer: ", typeof SharedArrayBuffer === "function");
  }, []);

  function getSignature(e) {
    e.preventDefault();

    fetch(authEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        meetingNumber: idMeeting,
        role: email == inputEmail ? 1 : 0,
      })
    }).then(res => res.json())
      .then(response => {
        startMeeting(response.signature)
      }).catch(error => {
        console.error("er", error)
      })
  }

  useEffect(() => {
    if (isMobile) {
      var node = document.querySelector('[title="Share Screen"]');
      if (node) {
        node.style.display = "none";
      }
    }

  }, [isMobile])

  useEffect(() => {
    console.log("user", user)
  }, [user])

  useEffect(() => {
    console.log("client changed", client)
  }, [client])


  function startMeeting(signature) {
    try {

      let meetingSDKElement = document.getElementById('meetingSDKElement');
      let meetingSDKChatElement = document.getElementById('meetingSDKChatElement')
      console.log("a",meetingSDKChatElement)
      client.init({
        enforceMultipleVideos: true,
        zoomAppRoot: meetingSDKElement,
        disableRecord: false,
        disablePreview: false,
        isSupportAV: true,
        language: 'en-US',
        disableDraggable: true,
        leaveUrl: leaveUrl,
        customize: {
          video: {
            isResizable: false,
            defaultViewType: isMobile ? ("ribbon") : ("speaker"),
            popper: {
              disableDraggable: true
            },
            viewSizes: {
              default: {
                width: isMobile ? 300 : 800,
                height: isMobile ? 300 : "100%",
              },
              ribbon: {
                width: isMobile ? 240 : 250,
                height: isMobile ? 135 : 150,
              }
            },
          },          
          chat: {
            popper: {
              disableDraggable: true,
              anchorElement: meetingSDKChatElement,
              placement: 'top'
            }
          }
        },
      })
        .then((e) => {
          console.log("init success:", e);
          let startBtn = document.getElementById("start-my-meeting-swz");
          if (startBtn) {
            startBtn.style.display = "none";
          }
          client.join({
            signature: signature,
            sdkKey: sdkKey,
            meetingNumber: meetingNumber,
            password: passWord,
            userName: userName,
            userEmail: userEmail,
            tk: registrantToken,
            zak: zakToken,
          }).then((e) => {
            console.log("join success", e);
            console.log(client.getCurrentUser());
            setuser(client.getCurrentUser());
          })
            .catch((e) => {
              console.log("join error", e);
            });
        })
        .catch((e) => {
          console.log("init error", e);
        });

      client.on("user-updated", (payload) => {
        setuser({ ...user, ...payload?.find((val, index) => val?.userId === user?.userId) })
      });
      client.on("user-added", (payload) => {
        console.log("payload add", payload)
        setuser({ ...user, ...payload?.find((val, index) => val?.userId === user?.userId) })
      });
      client.on("connection-change", (payload) => {
        console.log("connection:", payload)
        if (payload?.state === "Closed") {
          window.location.replace(window.location.origin);
        }
      });

    } catch (error) {
      console.log("error", error)
    }

  }



  return (
    <main className="fullView-meeting-container">
      {lauchMeeting && (
        <div>
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#B01E55", "#B01E55", "blue", "#B01E55", "#blue"]}
          />
          <h3 style={{ fontWeight: "bold" }}>Loading Meeting...</h3>
        </div>
      )}
      <button id="start-my-meeting-swz" style={{ display: "contents" }} onClick={getSignature}>
        Join Meeting
      </button>

      {/* For Component View */}
      {user &&
        <div className="meeting-container-personalized">
          <div id="meetingSDKElement">
            {/* Zoom Meeting SDK Component View Rendered Here */}
          </div>
          <div className="column">
            <div id="meetingSDKChatElement">
              {/* Zoom Meeting SDK Chat*/}
            </div>
          </div>

        </div>
      }
      {/* <button onClick={getSignature}>Join Meeting</button>  */}
    </main>
  );
}

export default ZoomEmbedded;
