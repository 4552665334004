import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Alert, Box, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import Navbar from "../../components/navbar/Navbar";
import PaymentForm from "../../components/PayCreditCard/PaymentForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import InlineCartPageSub from "../../components/CollectJsSection/InlineCartFormSub";
import { Footer } from "../../components/Footer/Footer";
import "./index.scss";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Button from "@mui/material/Button";
import { PlanCard } from "../../components/PlanCard/PlanCard";
import { isMobile } from "react-device-detect";
import { countriesData, statesData } from "../../data/mockData";
import _ from "lodash";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { PlanCardInfo } from "../../components/PlanCard/PlanCardInfo";
import { emptyValue } from "../../utils/validations/validateForm";
import StepperColored from "./stepperColored";
import FormControl from '@mui/material/FormControl';
import "./forms.css"

const planId_global = "swzpremiummonthly";
const plan_global = "12.99";

const Payments = () => {
  const { user, getAccessTokenSilently, isLoading } = useAuth0();
  let [searchParams, setSearchParams] = useSearchParams();
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [zip, setZip] = useState(null);
  const [username, setusername] = useState(user.nickname);
  const [state, setState] = useState(null);
  const [firstStep, setFirstStep] = useState(true);
  const [secondStep, setSecondStep] = useState(false);
  const [lastStep, setlastStep] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [countries, setCountries] = useState();
  const [dataLocation, setDataLocation] = useState();
  const [groupStates, setGroupStates] = useState();
  const [countrySelected, setCountrySelected] = useState();
  const [totalCard, setTotalCard] = useState(0);
  const [planId, setPlanId] = useState(null);
  const [myToken, setMyTOken] = useState();
  const [firstValidate, setFirstValidate] = useState([]);
  const [step_position, setstep_position] = useState(0)
  const [stateEnable, setStateEnable] = useState(false)
  const [errorObject, seterrorObject] = useState({});
  const [fetch_failed, setfetch_failed] = useState(false);
  const [sending, setSending] = useState(false);
  const [alertPersonalized, setalertPersonalized] = useState(undefined);
  const [errorusername, seterrorusername] = useState(false);
  const [validating, setValidating] = useState(false)
  const steps = ['Personal Information', 'Verify', "" ];

  const navigate = useNavigate();


  useEffect(() => {
    if (user["http://locahost:5000/roles"]?.length!=0) {
        window.location.replace("/");
    }
  }, [isLoading]) 
  
  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const nombre = params.get("nombre");
    if(!window.sessionStorage.getItem("set_up_plan_payment")){
      window.sessionStorage.setItem("set_up_plan_payment",JSON.stringify({planId: planId_global,plan: plan_global,}));
      setTotalCard(plan_global);
      setPlanId(planId_global)
    }else{
      setTotalCard(JSON.parse(window.sessionStorage.getItem("set_up_plan_payment"))?.plan);
      setPlanId(JSON.parse(window.sessionStorage.getItem("set_up_plan_payment"))?.planId);
    }
    setGroupStates(
      _(statesData)
        .groupBy((x) => x.country_id)
        .map((value, key) => ({ idCountry: key, info: value }))
        .value()
    );

    const getMyToken = async () => {
      setMyTOken(await getAccessTokenSilently());
    };

    const getPostMessage = () => {
      window.addEventListener(
        "message",
        function (event) {
          if (event.data.type === "payment") {
            window.location.replace("/verify");
            // se realiza una acción en respuesta al mensaje recibido
          }
        },
        false
      );
    };
    getPostMessage();
    getMyToken();
  }, []);

  useEffect(() => {
    const options = {
      method: "GET",
    };

    fetch(`https://countriesnow.space/api/v0.1/countries`, options)
      .then((res) => res.json())
      .then((res) => {
        setDataLocation(res.data);
      })
      .catch((err) => console.log("New Error: ", err));
  }, []);

  const onBlurInput = (e) => {
    seterrorObject({...errorObject, [e.target.name]:emptyValue(e.target.value)})
  }

  function getNextBtnStatus(){
    if(step_position===0){
      if(stateEnable){
        return !firstName || !lastName || !username || !phoneNumber || !zip || !address1 || !city || !country ;
      }else{
        return !firstName || !lastName || !username || !phoneNumber || !zip || !address1 || !city || !state || !country ;
      }
    }
  }

  function onClickNext (){
    if(step_position===2){
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${myToken}`,
          comp: process.env.REACT_APP_COMP,
          user_id:username,
        },
      };
      fetch(
        "https://companduser-develop.herokuapp.com/user",
        options
      )
        .then((res) => res.json())
        .then((res) => {     
          if(res.status == 200){
            if(res){
              setalertPersonalized({severity:"error", color:"error", message:"The username is not available!! please try with another username. The username must be unique."})
              seterrorusername(true);
            }else{
              setalertPersonalized({severity:"success", color:"success", message:"The username is available! Check that the information is correct before creating the user."})
              setSending(true);
              setfetch_failed(false);
              seterrorusername(false);
              setstep_position(0);
            }
          }
        })
        .catch((err) => {
          setalertPersonalized({severity:"error", color:"error", message:"The username is not available!! please try with another username. The username must be unique."})

          setfetch_failed(true);
          setSending(false);
          console.log("Error Suscription: ", err);
        }); 
        return ;
    }
    setstep_position(step_position+1)
  }

  useEffect(() => {
    setStateEnable(
      groupStates?.find(val => val?.idCountry == country?.id)
      ? false
      : true
    )

  }, [groupStates,country])

  const createUser = async (e)=>{
    setValidating(true);
    e.preventDefault()
    //src={`https://nmifront.vercel.app/?total=${totalCard}&subAuth=${user.sub}&planId=${planId}&firstName=${firstName}&lastName=${lastName}&city=${city}&phone=${phoneNumber}&emailAddress=${user.email}&paymentType=swz&address1=${address1}&token=${myToken}&organization=testOrg`}

    let usuarioFinal = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      address: address1,
      country: country?.id,
      state: state,
      city: city,
      zip: zip,
      username: username,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${myToken}`,
        comp: process.env.REACT_APP_COMP,
      },
      body: JSON.stringify(usuarioFinal),
    };
    fetch(
      `https://meetingssellwithzoom-develop.herokuapp.com/usersZoomCGC`,
      options
    )
      .then((res) => res.json())
      .then((res) => { 
        setValidating(false);
        if(res.detail){          
          setfetch_failed(true);
          setSending(false);
          setstep_position(0);
          setalertPersonalized({severity:"error", color:"error", message:"The username is not available!! please try with another username. The username must be unique."})
        }else{       
          setSending(true);   
          setfetch_failed(false);
          setalertPersonalized({severity:"success", color:"success", message:"User created successfully"})
          window.location.replace("/");
          
        }  
        
        
      })
      .catch((err) => {
        setfetch_failed(true);
        setSending(false);
        setalertPersonalized({severity:"error", color:"error", message:"The username is not available!! please try with another username. The username must be unique."})
        console.log("Error Suscription: ", err);
      }); 
    
  } 

  return (
    <Box className="payment-container-page">
      <Box className="form-content">
        <Box className="form-content-payment-title">
          <Typography variant="h4" fontWeight="bold">
            <span style={{ color: "#88609F" }}>Welcome!</span> Continue with the registration process
          </Typography>
          <Typography variant="h6" fontWeight="500">
            We are pleased to have you here, to continue you must complete the payment information.
          </Typography>
        </Box>
        <Box
          className="cards-payment-container"
          sx={{
            display: "flex",
            gap: "15px",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <PlanCardInfo
            planTitle="The original WorkingLive Webinar"
            planName={planId ==="swzpremiummonthly" ? "Licensed" : ""}
            basic={planId ==="swzpremiummonthly" ? true : false}
            selected={true}
          />
          <Box className="form-content-first-box" style={{border:"solid 1px #d6d6d6", padding:"10px", borderRadius: "10px", backgroundColor:"white" }}>
            <div style={{width:"100%"}}>
              {alertPersonalized?.severity
              ?
              <Alert severity={alertPersonalized?.severity} color={alertPersonalized?.info} >
                <p style={{fontSize:"12px", margin:0}}>
                  {alertPersonalized?.message} 
                </p>
              </Alert>
              :<Alert severity="info" color="success">
                <p style={{fontSize:"12px", margin:0}}>
                  {"*The original WorkingLive Webinar plan has been selected, a charge of "}
                  <span style={{ fontWeight: "bold", color: "#8a8a8a" }}> ${totalCard}
                  </span>{" will be made to your payment method. "}
                  <span style={{ fontWeight: "bold", color: "#8a8a8a" }}> {" (This subscription is"}{planId == "swzpremiummonthly" ? "monthly" : "yearly"}{")."} </span>
                </p>
              </Alert>
            }
              
            </div> 

            <Box style={{  width: "100%", paddingBottom:"5px" }}>                            
              <Typography
                variant="h5"
                fontWeight="bold"
                fontFamily="inherit"
                marginBottom="10px"
              >
                Payment Information
              </Typography>
              <div className="payment_stepper_content">
                <div style={{width:"90px"}}>
                  {step_position >0 &&
                    <Button
                      className="start-meeting-tab only-next-payment"
                      style={{width:"100%"}}
                      onClick={() => { setstep_position(step_position-1);  }}
                    >
                      <ChevronLeftIcon /> Back
                    </Button>
                  }
                </div>                
                <div style={{flex:"1 1"}}><StepperColored steps={steps} active_step={step_position}/></div>                
                <div style={{width:"90px"}}>
                  {step_position < steps.length-1 && step_position !=1 && 
                    <Button
                      className="start-meeting-tab only-next-payment"
                      style={{width:"100%"}}
                      onClick={() => { onClickNext()}}
                      disabled={getNextBtnStatus()}
                    >
                      Next <NavigateNextIcon />
                    </Button>
                  }
                </div>                
              </div>              
            </Box>

            {step_position === 0 && (
            <Box
              className="payment_form class-for-move"
              component="form"
              noValidate
              autoComplete="off"
            >
              <div className='container-twoflex-options'>
                <TextField
                  label="First Name"
                  type="text"
                  name="firstName"
                  value={firstName || ""}
                  onChange={(e)=>{setFirstName(e.target.value)}}  
                  onBlur={(e)=>{onBlurInput(e)}}
                  id="input-with-icon-textfield"
                  fullWidth 
                  error={firstName?.trim !="" && firstName ? false : true}
                  variant="outlined"
                  size='small'
                  placeholder="ex: Richard"
                  required
                />
                <TextField
                  label="Last Name"
                  type="text"
                  name="lastName"
                  value={lastName || ""}
                  onChange={(e)=>{setLastName(e.target.value)}}  
                  onBlur={(e)=>{onBlurInput(e)}}
                  id="input-with-icon-textfield"
                  fullWidth 
                  error={lastName?.trim !="" && lastName ? false : true}
                  variant="outlined"
                  size='small'
                  placeholder="ex: Venegas"
                  required
                />
              </div>
              <div className='container-twoflex-options'>
                <TextField
                  type="number"
                  pattern="[0-9]{10}"
                  label="Phone Number"
                  name="phoneNumber"
                  value={phoneNumber || ""}
                  onChange={(e)=>{setPhoneNumber(e.target.value)}}  
                  onBlur={(e)=>{onBlurInput(e)}}
                  id="input-with-icon-textfield"
                  fullWidth 
                  error={phoneNumber?.trim !="" && phoneNumber ? false : true}
                  variant="outlined"
                  size='small'
                  placeholder="000 000 0000"
                  required
                />
                <TextField
                  label="Username"
                  name="username"
                  value={username || ""}
                  onChange={(e)=>{setusername(e.target.value)}}  
                  onKeyDown={(e)=>{         
                    if (!/[a-zA-Z0-9]/.test(e.key)){
                      e.preventDefault()
                    }
                  }}
                  onBlur={(e)=>{onBlurInput(e)}}
                  id="input-with-icon-textfield"
                  fullWidth 
                  error={username?.trim !="" && username && !errorusername? false : true}
                  variant="outlined"
                  size='small'
                  placeholder="example29"
                  required
                />
              </div>
              <TextField
                label="Address Line 1"
                name="address1"
                value={address1 || ""}
                onChange={(e) => setAddress1(e.target.value)}
                onBlur={(e)=>{onBlurInput(e)}}
                id="input-with-icon-textfield"
                fullWidth 
                error={address1?.trim !="" && address1 ? false : true}
                variant="outlined"
                size='small'
                placeholder="ex: 305 Creek RD"
                required
              />
              <div className='container-twoflex-options'>
                <FormControl size="small">
                  <InputLabel id="select-label-country">Country</InputLabel>
                  <Select
                    labelId="select-label-country"
                    id="country"
                    value={JSON.stringify(country)}
                    label="Country"
                    error={JSON.stringify(country)?.trim !="" && JSON.stringify(country) ? false : true}
                    placeholder="ex: Canada"
                    onChange={(e) => {
                      if(e.target.value && e.target.value!=""){
                        setCountry(JSON.parse(e.target.value));
                        setCountrySelected(JSON.parse(e.target.value).iso2);
                      }else{
                        setCountry(undefined);
                      }
                    }}
                  >
                    <MenuItem value=""><em>--Select a Country--</em></MenuItem>
                    {countriesData?.map((data) => {
                      return (
                        <MenuItem value={JSON.stringify(data)}> {data.name}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl size="small">
                  <InputLabel id="select-label-state">State</InputLabel>
                  <Select
                    labelId="select-label-state"
                    id="state"
                    value={state}
                    label="State"
                    disabled={stateEnable}
                    error={state !="" && state ? false : true}
                    placeholder="ex: Montreal"
                    onChange={(e) => {
                      setState(e.target.value);
                    }}
                  >
                    <MenuItem value=""><em>--Select a State--</em></MenuItem>
                    {groupStates?.map((data) => {
                      if (parseInt(data?.idCountry) == country?.id) {
                        return data?.info?.map((infoState) => {
                          return (<MenuItem value={infoState?.state_code}>{infoState?.name}</MenuItem>);
                        });
                      }
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className='container-twoflex-options'>
                <TextField
                  label="City"
                  name="city"
                  value={city || ""}
                  onChange={(e) => setCity(e.target.value)}
                  onBlur={(e)=>{onBlurInput(e)}}
                  id="input-with-icon-textfield"
                  fullWidth 
                  error={city?.trim !="" && city ? false : true}
                  variant="outlined"
                  size='small'
                  placeholder="ex: Sevierville"
                  required
                />
                <TextField
                  label="Zip"
                  name="lastName"
                  value={zip || ""}
                  onChange={(e)=>{setZip(e.target.value)}}  
                  onBlur={(e)=>{onBlurInput(e)}}
                  id="input-with-icon-textfield"
                  fullWidth 
                  error={zip?.trim !="" && zip ? false : true}
                  variant="outlined"
                  size='small'
                  placeholder="ex: 37645"
                  required
                />
              </div>
            </Box>)}

            {step_position === 1 && (
            <Box
              className="payment_form class-for-move"
              component="form"
              noValidate
              autoComplete="off"
            >
              <p className="verify-title">Please verify that the information entered is correct. Once verified, click on Create user.</p>
              <div style={{maxWidth:"500px", width:"100%", display:"flex", flexDirection:"column"}}>
                <div className='container-twoflex-options'>
                  <p className="verify-content"><b >{"First Name: "}</b>{firstName||""}</p>
                  <p className="verify-content"><b>{"Last Name: "}</b>{lastName||""}</p> 
                </div>
                <div className='container-twoflex-options'>
                  <p className="verify-content"><b>{"Username: "}</b>{username||""}</p>
                  <p className="verify-content"><b>{"Phone Number: "}</b>{phoneNumber||""}</p>
                </div>
                <p className="verify-content"><b>{"Address Line 1: "}</b>{address1||""}</p>
                <div className='container-twoflex-options'>
                  <p className="verify-content"><b>{"Country: "}</b>{country?.name}</p>
                </div>
                <div className='container-twoflex-options'>
                  <p className="verify-content"><b>{"City: "}</b>{city||""}</p>
                  <p className="verify-content"><b>{"Zip: "}</b>{zip||""}</p>
                </div>
                <Button
                  className="start-meeting-tab verify-pay-info"
                  style={{width:"400px",alignSelf: "center", marginTop: "20px"}}
                  disabled={validating || sending}
                  onClick={(e) => { createUser(e)}}
                >
                  {validating? "Validating username ..." :sending ? "Sending information ...":"Correct Information, Proceed to create"}
                </Button>
              </div>
            
            </Box>)}
            
          </Box>
        </Box>
      </Box>
      <Footer></Footer>
    </Box>
  );
};

export default withAuthenticationRequired(Payments);
