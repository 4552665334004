import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Header from "../../components/Header";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";
import Layout from "../global/Layout";
import { useAuth0 } from "@auth0/auth0-react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import {
  getUserSettings,
  updateZoomConfig,
} from "../../services/zoom/zoomconfig";
import { ModalConfirm } from "../../components/ModalConfirm";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#B11E55" : "#B11E55",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#9C9C9C" : "#9C9C9C",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const Profile = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [requirePsw, setRequirePsw] = useState(false);
  const [hostVideo, setHostVideo] = useState(false);
  const [liveStreaming, setLiveStreaming] = useState(false);
  const [breakoutRoom, setBreakOutRoom] = useState(false);
  const [autoRecording, setAutoRecording] = useState("none");
  const [shareMeeting, setShareMeeting] = useState(false);
  const [waitingRoom, setWaitingRoom] = useState(false);
  const [personalMID, setPersonalMID] = useState(false);
  const [participantVideo, setParticipantVideo] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const updateUserSetings = async () => {
    setOpenModal(false)
    setLoadingInfo(true);
    const myToken = await getAccessTokenSilently();
    const payload = {
      schedule_meeting: {
        host_video: hostVideo,
        participants_video: participantVideo,
        require_password_for_scheduling_new_meetings: requirePsw,
        personal_meeting: personalMID,
      },
      in_meeting: {
        allow_live_streaming: liveStreaming,
        breakout_room: breakoutRoom,
        disable_screen_sharing_for_in_meeting_guests: shareMeeting,
        waiting_room: waitingRoom,
      }, 
      recording:{
        auto_recording: autoRecording,
      } 
    };

    const sendUserSettings = await updateZoomConfig(myToken, payload);
    setLoadingInfo(false);
  };

  useEffect(() => {
    setLoadingInfo(true);
    const loadUserSettings = async () => {
      const myToken = await getAccessTokenSilently();
      const gettingUserSettings = await getUserSettings(myToken);
      if (gettingUserSettings.data) {
        setRequirePsw(
          gettingUserSettings.data?.schedule_meeting
            ?.require_password_for_scheduling_new_meetings
        );
        setHostVideo(gettingUserSettings.data?.schedule_meeting?.host_video);
        setParticipantVideo(
          gettingUserSettings.data?.schedule_meeting?.participants_video
        );
        setLiveStreaming(
          gettingUserSettings.data?.in_meeting?.allow_live_streaming
        );
        setBreakOutRoom(gettingUserSettings.data?.in_meeting?.breakout_room);
        setShareMeeting(
          gettingUserSettings.data?.in_meeting
            ?.disable_screen_sharing_for_in_meeting_guests
        );
        setWaitingRoom(gettingUserSettings.data?.in_meeting?.waiting_room);
        setPersonalMID(
          gettingUserSettings.data?.schedule_meeting?.personal_meeting
        );
        setAutoRecording(gettingUserSettings?.data?.recording?.auto_recording);
      }
      setLoadingInfo(false);
    };
    loadUserSettings();
  }, []);
  

  return (
    <>
      <Layout>
        <Box m="20px">
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Header title="Account Settings" />
          </Box>

          {!loadingInfo && (
            <Box>
              <Box className="subtitle-btn-container">
                <Typography variant="h4" textAlign="left" fontWeight="500">
                  <span style={{ fontWeight: "bold" }}>{user.given_name}</span>{" "}
                  This is your zoom account settings.
                </Typography>
                <Button
                  onClick={() => setOpenModal(true)}
                  className="start-meeting-tab"
                >
                  Save changes
                </Button>
              </Box>
              <Box className="zoom-setting-item">
                <Typography
                  variant="h4"
                  textAlign="left"
                  fontWeight="bold"
                  color="#777777"
                >
                  Waiting Room:
                </Typography>
                <Box className="zoom-setting-subitem">
                  <Typography variant="h6" textAlign="left">
                    This can be used by participants at any time to access your
                    personal virtual room.
                  </Typography>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={waitingRoom}
                        onClick={() => setWaitingRoom(!waitingRoom)}
                      />
                    }
                  />
                </Box>
              </Box>
              <Box className="zoom-setting-item">
                <Typography
                  variant="h4"
                  textAlign="left"
                  fontWeight="bold"
                  color="#777777"
                >
                  Require Password for future meetings:
                </Typography>
                <Box className="zoom-setting-subitem">
                  <Typography variant="h6" textAlign="left">
                    This can be used by participants at any time to access your
                    personal virtual room.
                  </Typography>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={requirePsw}
                        onClick={() => setRequirePsw(!requirePsw)}
                      />
                    }
                  />
                </Box>
              </Box>
              <Box className="zoom-setting-item">
                <Typography
                  variant="h4"
                  textAlign="left"
                  fontWeight="bold"
                  color="#777777"
                >
                  Facebook/Youtube Live Streaming:
                </Typography>
                <Box className="zoom-setting-subitem">
                  <Typography variant="h6" textAlign="left">
                    This can be used by participants at any time to access your
                    personal virtual room.
                  </Typography>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={liveStreaming}
                        onClick={() => setLiveStreaming(!liveStreaming)}
                      />
                    }
                  />
                </Box>
              </Box>
              <Box className="zoom-setting-item">
                <Typography
                  variant="h4"
                  textAlign="left"
                  fontWeight="bold"
                  color="#777777"
                >
                  Host Video:
                </Typography>
                <Box className="zoom-setting-subitem">
                  <Typography variant="h6" textAlign="left">
                    This can be used by participants at any time to access your
                    personal virtual room.
                  </Typography>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={hostVideo}
                        onClick={() => setHostVideo(!hostVideo)}
                      />
                    }
                  />
                </Box>
              </Box>
              <Box className="zoom-setting-item">
                <Typography
                  variant="h4"
                  textAlign="left"
                  fontWeight="bold"
                  color="#777777"
                >
                  Participant Video:
                </Typography>
                <Box className="zoom-setting-subitem">
                  <Typography variant="h6" textAlign="left">
                    This can be used by participants at any time to access your
                    personal virtual room.
                  </Typography>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={participantVideo}
                        onClick={() => setParticipantVideo(!participantVideo)}
                      />
                    }
                  />
                </Box>
              </Box>
              <Box className="zoom-setting-item">
                <Typography
                  variant="h4"
                  textAlign="left"
                  fontWeight="bold"
                  color="#777777"
                >
                  Breakout Rooms:
                </Typography>
                <Box className="zoom-setting-subitem">
                  <Typography variant="h6" textAlign="left">
                    This can be used by participants at any time to access your
                    personal virtual room.
                  </Typography>
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    checked={breakoutRoom}
                    onClick={() => setBreakOutRoom(!breakoutRoom)}
                  />
                </Box>
              </Box>
              <Box className="zoom-setting-item">
                <Typography
                  variant="h4"
                  textAlign="left"
                  fontWeight="bold"
                  color="#777777"
                >
                  Auto Recording:
                </Typography>
                <Box className="zoom-setting-subitem">
                  <Typography variant="h6" textAlign="left">
                    This can be used by participants at any time to access your
                    personal virtual room
                  </Typography>
                  <FormControlLabel 
                    control={<IOSSwitch sx={{ m: 1 }} 
                    checked={autoRecording=="none"?false:true}
                    onClick={() => {
                      setAutoRecording(autoRecording=="none"?"cloud":"none")
                    }}/>} />
                </Box>
              </Box>
              <Box className="zoom-setting-item">
                <Typography
                  variant="h4"
                  textAlign="left"
                  fontWeight="bold"
                  color="#777777"
                >
                  Audio Transcript:
                </Typography>
                <Box className="zoom-setting-subitem">
                  <Typography variant="h6" textAlign="left">
                    This can be used by participants at any time to access your
                    personal virtual room.
                  </Typography>
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                  />
                </Box>
              </Box>
              <Box className="zoom-setting-item">
                <Typography
                  variant="h4"
                  textAlign="left"
                  fontWeight="bold"
                  color="#777777"
                >
                  Screen Share:
                </Typography>
                <Box className="zoom-setting-subitem">
                  <Typography variant="h6" textAlign="left">
                    This can be used by participants at any time to access your
                    personal virtual room.
                  </Typography>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={shareMeeting}
                        onClick={() => setShareMeeting(!shareMeeting)}
                      />
                    }
                  />
                </Box>
              </Box>
              <Box className="zoom-setting-item">
                <Typography
                  variant="h4"
                  textAlign="left"
                  fontWeight="bold"
                  color="#777777"
                >
                  Use personal meeting id:
                </Typography>
                <Box className="zoom-setting-subitem">
                  <Typography variant="h6" textAlign="left">
                    This can be used by participants at any time to access your
                    personal virtual room.
                  </Typography>
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    checked={personalMID}
                    onClick={() => setPersonalMID(!personalMID)}
                  />
                </Box>
              </Box>
            </Box>
          )}
          {loadingInfo && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
              flexDirection="column"
            >
              <CircularProgress
                style={{ color: "#00000", width: "70px", height: "70px" }}
              />
              <Typography variant="h6" fontWeight="bold">
                Loading...
              </Typography>
            </Box>
          )}
        </Box>
      </Layout>
      <ModalConfirm
        actionFunc={updateUserSetings}
        openModal={openModal}
        setOpenModal={setOpenModal}
        subTitleModal="Are you sure you want to update the settings?"
        titleModal="Update Settings"
      />
    </>
  );
};

export default withAuthenticationRequired(Profile);
