import React, { useEffect, useState } from "react";
import { Box, Typography, Button, TextField } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import "./index.scss";
import { QRCode } from "react-qrcode-logo";
import html2canvas from 'html2canvas';
import Modal from "react-modal";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import LinkIcon from '@mui/icons-material/Link';
import MailIcon from '@mui/icons-material/Mail';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';

Modal.setAppElement("#root");
export const CardDetailRecording = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [copiedLink, setCopiedLink] = useState(false);
  const [copiedQR, setCopiedQR] = useState(false);
  const [meetingID, setmeetingID] = useState("");
  const [startTime, setStartTime] = useState("");
  const [startDate, setStartDate] = useState("")

  
  useEffect(() => {
    const dateStart = new Date(props.meeting?.start_time).toLocaleTimeString("en-US", {timeZone: props.meeting?.time_zone,});
    setStartTime([dateStart.split(":")[0],dateStart.split(":")[1],].join(":") +" " +dateStart.split(" ")[1] +" ")
    setStartDate(new Date(props.meeting?.start_time).toDateString())
    setmeetingID(props.meeting?.join_url?.split("?")[0].split("/").reverse()[0]);
  }, [props]);


  function formatSizeUnits(bytes){
    if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
    else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
    else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed(2) + " KB"; }
    else if (bytes > 1)           { bytes = bytes + " bytes"; }
    else if (bytes == 1)          { bytes = bytes + " byte"; }
    else                          { bytes = "0 bytes"; }
    return bytes;
  }

  const copyText = (meeting_id) => {
    navigator.clipboard
      .writeText(meeting_id)
      .then(() => {
        setCopiedLink(true);
      })
      .catch((err) => {
        setCopiedLink(false);
        console.log("Something went wrong", err);
      });
  };

  const openNewTab = (url) => {
    window.open(url, "_blank");
  };

  const facebook = "/assets/images/virtual_skin/fb.svg";
  const telegram = "/assets/images/virtual_skin/tel.svg";
  const msjText = "/assets/images/virtual_skin/msj.svg";
  const tw = "/assets/images/virtual_skin/tw.svg";
  const email = "/assets/images/virtual_skin/mail.svg";

  return (
    <div className="recording-container">
        <div className="recording-container-info">
            <PlayCircleOutlinedIcon
                className="play-button"
                onClick={() =>
                    window.open(props?.meeting?.share_url)
                }
            /> 
            <div className="recording-information" style={{borderRight: "1px solid #c7c7c7"}}>
                <span className="recording-information-date">{startDate}</span>
                <p className="recording-information-time">{startTime}</p>
            </div> 
            <div className="recording-information">
                <span className="recording-information-title">{props.meeting?.topic}</span>
                <p className="recording-information-description">{"("+formatSizeUnits(props.meeting?.total_size)+")" }</p>
            </div>    
                   
        </div>
        <div className="recording-container-options">
            <span className="share-text">SHARE</span>
            <div className="share-container">
                <button className="share-recording-link-item"
                    onClick={() =>openNewTab(`https://www.facebook.com/sharer/sharer.php?u=${props.meeting?.share_url}`)}
                >
                    <FacebookRoundedIcon sx={{color:"white",fontSize: 18}} />
                </button>
                <button className="share-recording-link-item"
                    onClick={() => {openNewTab(`https://t.me/share/url?url=${props.meeting?.share_url}`);}}
                >
                    <SendRoundedIcon sx={{color:"white",fontSize: 18}} />
                </button>
                <button className="share-recording-link-item"
                    onClick={() =>openNewTab(`sms:?&body=${props.meeting?.share_url}`)}
                >
                    <SpeakerNotesOutlinedIcon sx={{color:"white",fontSize: 18}} />
                </button>
                <button className="share-recording-link-item"
                    onClick={() =>openNewTab(`mailto:info@example.com?&subject=&cc=&bcc=&body=${props.meeting?.share_url}`)}
                >
                    <MailIcon sx={{color:"white",fontSize: 18}} />
                </button>
                {/* <Box
                className="share-recording-link-item"
                onClick={() =>openNewTab(`https://www.facebook.com/sharer/sharer.php?u=${props.meeting?.share_url}`)}
              >
                <img
                    alt="facebook"
                    src={facebook}
                    width="27px"
                    height="27px"
                    style={{ borderRadius: "50%" }}
                />
              </Box> */}
              {/* <Box
                className="share-recording-link-item"
                onClick={() => {openNewTab(`https://t.me/share/url?url=${props.meeting?.share_url}`);}}
              >
                <img
                    alt="telegram"
                    src={telegram}
                    width="27px"
                    height="27px"
                    style={{ borderRadius: "50%" }}
                />
              </Box> */}
             {/*  <Box
                className="share-recording-link-item"
                onClick={() =>openNewTab(`sms:?&body=${props.meeting?.share_url}`)}
              >
                <img
                    alt="messages"
                    src={msjText}
                    width="27px"
                    height="27px"
                    style={{ borderRadius: "50%" }}
                />
              </Box> */}
              {/* <Box
                className="share-recording-link-item"
                onClick={() =>openNewTab(`mailto:info@example.com?&subject=&cc=&bcc=&body=${props.meeting?.share_url}`)}
              >
                <img
                    alt="email"
                    src={email}
                    width="27px"
                    height="27px"
                    style={{ borderRadius: "50%", color:"black"}}
                />
              </Box> */}
            </div>
            
        </div>
        <div className="recording-container-options">
            <span
                className="watch-button"
                onClick={() => window.open(props?.meeting?.share_url)}
            >
                <LaunchIcon style={{ color: "#779F60" }} /> {"Watch Now"}
            </span>
            <span
                className={copiedLink ? "link-button-copied":"link-button"}
                onClick={() => copyText(props.meeting?.share_url)}
            >
                <LinkIcon size={22}/>{copiedLink ? "Copied Link" : "Copy Link"}
            </span>
            
        </div>
        

    </div>
  )
}