import React from "react";
import { useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { Box, Button, TextField, MenuItem, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import Header from "../../components/Header";
import SubTitle from "../../components/Subtitle";
import CircularProgress from "@mui/material/CircularProgress";
import Layout from "../global/Layout";
import { useAuth0 } from "@auth0/auth0-react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import ProfileImage from "../../components/ChangeProfileImage";

const Settings = () => {
  const userURL = "https://companduser-develop.herokuapp.com/user";
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [userInfo, setUserInfo] = useState({});
  const { user, getAccessTokenSilently } = useAuth0();
  const [enable, setEnable] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);

  const handleFormSubmit = (values) => {
    console.log(values);
  };

  useEffect(() => {
    console.log("user", user)
    const loadUserSettings = async () => {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
          comp: process.env.REACT_APP_COMP,
        },
      };
      fetch(userURL, options)
        .then((response) => response.json())
        .then((response) => {
          console.log("response", response)
          if (!response?.detail) {
            setUserInfo(response)
          }
          setLoadingInfo(false);
        })
        .catch((error) => {
          console.log("error", error);
          setLoadingInfo(false);
        });
    };
    loadUserSettings();

  }, [user?.sub])


  const handleEnable = () => {
    setEnable(!enable);
  };
  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const checkoutSchema = yup.object().shape({
    nickname: yup.string().required("First Name is a required field 🔴"),
    first_name: yup.string().required("First Name is a required field 🔴"),
    last_name: yup.string().required("Last Name is a required field 🔴"),
    email: yup
      .string()
      .email("Invalid email")
      .required(`Email is a required field ${"🔴"}`),

  });

  const initialValues = {
    nickname: userInfo?.nickname,
    first_name: userInfo?.first_name,
    email: user?.email,
    last_name: user?.last_name,
    member_id: userInfo?.member_id
  };
  return (
    <Layout>
      <Box m="20px" sx={{height:"80vh",}}>
        <Box
          style={{            
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Header title="Profile Information" />
        </Box>
        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", maxWidth:"700px", marginRight:"auto", marginLeft:"auto"}}>
          <div style={{ display: "flex", flexDirection: "column", padding:"15px 20px 10px 20px"}}>
            <ProfileImage />
            <img alt="" width={"150px"} className="show-profile-img" src={user?.picture} />
            <Typography variant="h4" className="name-main-profile">
              {user?.name}
            </Typography>
          </div>
          <div style={{ display: "flex", flexDirection: "column", flex: "1 1 0%", rowGap: "15px" }}>
            <SubTitle subText="EDIT INFORMATION"></SubTitle>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              size="small"
              label="First Name"
              value={userInfo?.first_name || ""}
              name="first_name"
              special="first"
              sx={{ gridColumn: "span 4", fontSize: "14px" }}
              className="field-input override-textfiled"
              disabled={!enable}
            />
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              size="small"
              label="Last Name"
              value={userInfo?.last_name || ""}
              special="first"
              name="last_name"
              sx={{ gridColumn: "span 4" }}
              disabled={!enable}
            />
            <TextField
              fullWidth
              variant="outlined"
              type="email"
              size="small"
              label="Email address"
              special="first"
              value={userInfo?.email || ""}
              name="email"
              sx={{ gridColumn: "span 4" }}
              disabled={!enable}
            />
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              size="small"
              label="Nickname"
              value={userInfo?.nickname || ""}
              special="first"
              name="nickname"
              sx={{ gridColumn: "span 4" }}
              disabled={!enable}
            />
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              size="small"
              label="Member ID"
              value={userInfo?.member_id || ""}
              special="first"
              name="member_id"
              sx={{ gridColumn: "span 4" }}
              disabled={!enable}
            />
          </div>
        </div>
        {/* <ProfileImage />
        <img alt="" className="show-profile-img" src={user?.picture} />
        <Typography variant="h4" className="name-main-profile">
          {user?.name}
        </Typography>
        {!loadingInfo && (
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={userInfo}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="20px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <SubTitle subText="EDIT INFORMATION"></SubTitle>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="First Name"
                    value={values?.first_name}
                    name="first_name"
                    special="first"
                    sx={{ gridColumn: "span 4", fontSize: "14px" }}
                    className="field-input override-textfiled"
                    disabled={!enable}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Last Name"
                    value={values.last_name}
                    special="first"
                    name="last_name"
                    sx={{ gridColumn: "span 4" }}
                    disabled={!enable}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="email"
                    size="small"
                    label="Email address"
                    special="first"
                    value={values.email}
                    name="email"
                    sx={{ gridColumn: "span 4" }}
                    disabled={!enable}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Nickname"
                    value={values.nickname}
                    special="first"
                    name="nickname"
                    sx={{ gridColumn: "span 4" }}
                    disabled={!enable}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Member ID"
                    value={values.member_id}
                    special="first"
                    name="member_id"
                    sx={{ gridColumn: "span 4" }}
                    disabled={!enable}
                  />
                </Box>
              </form>
            )}
          </Formik>
        )} */}
        {loadingInfo && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            <CircularProgress
              style={{ color: "#babe49", width: "70px", height: "70px" }}
            />
          </Box>
        )}
      </Box>
    </Layout>
  );
};

export default withAuthenticationRequired(Settings);
