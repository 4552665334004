import { statusValidation } from "../../utils/auth/mockvalidation";

export const getUserStatus = async (token, sub) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      user_id:sub,
      comp: process.env.REACT_APP_COMP,
    },
  };
  const res = await fetch( "https://companduser-develop.herokuapp.com/user",options
  );

  const json = await res.json();

  if (!res.ok) {
    return { value: false };
  }

  if (res.ok) {
    return {
      value: json?.status?.toLowerCase() != statusValidation.USER_STATUS,
    };
  }
};
