import React from 'react'
import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import "../../App.css";
import "./index.scss";

export default function ValidZoomAlert({user}) {
  return (
    <Box className="alert-container-email">
        <Alert severity="info" sx={{ fontSize: "12px" }}>
        <AlertTitle sx={{ fontSize: "14px", fontWeight: "bold" }}>
            Info
        </AlertTitle>
        You must accept the ZOOM invitation that you received by email{" "}
        <strong>{user?.email}</strong> in order to use all ZOOM features.
        </Alert>
    </Box>
  )
}
