import { Box, Button, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import React, { useState } from "react";
import "./index.scss";
import logoZoom from "../../resources/logozoom.webp"
import { useAuth0 } from "@auth0/auth0-react";

export const CardMeeting = ({ details, isActive }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [copyLink, setCopyLink] = useState("Copy Share Link");

  const copyText = () => {
    navigator.clipboard
      .writeText(`${window.location.origin}/${details?.meeting_id}`)
      .then(() => {
        setCopyLink("Copied");
      })
      .catch((err) => {
        console.log("Something went wrong", err);
      });
  };

  const openMeeting = async () =>  {
    try {
      const myToken = await getAccessTokenSilently();
      localStorage.setItem("sax", myToken);
      localStorage.setItem("sb", user.sub);
      window.open(`${window.location.origin}/${details?.meeting_id}`, "_blank");

    } catch (error) {
      console.log("e", error)
    }    
    
  };

  return (
    <Box className="dashboard-card-meeting-container">
      <Box className="dashboard-card-meeting-container-header">
        <Typography variant="h4" fontWeight="bold" color="#412770">
          Your Next Meeting
        </Typography>
      </Box>
      <Box className="dashboard-card-meeting-container-body">
        <img        
          alt="Zoom Logo"
          width="50px"
          src={logoZoom}
        />
        <Box>
          <Typography color="#412770" variant="h5" fontWeight="bold">
            {details?.topic ? details?.topic : "You have"}
          </Typography>
          <Typography color="#412770" variant="h5" fontWeight="bold">
            {details?.start_time
              ? new Date(details?.start_time).toDateString()
              : "no upcoming "}
          </Typography>
          {details?.start_time ? (
            <Typography color="#412770" variant="h6">
              {
                new Date(details?.start_time)
                  .toLocaleTimeString("en-US", {
                    timeZone: details?.time_zone,
                  })
                  .split(":")[0]
              }
              :
              {
                new Date(details?.start_time)
                  .toLocaleTimeString("en-US", {
                    timeZone: details?.time_zone,
                  })
                  .split(":")[1]
              }{" "}
              {
                new Date(details?.start_time)
                  .toLocaleTimeString("en-US", {
                    timeZone: details?.time_zone,
                  })
                  .split(" ")[1]
              }{" "}
              {details?.time_zone?.replace("_", " ")}
            </Typography>
          ) : (
            <Typography color="#412770" variant="h6"> 
             meetings
            </Typography>
          )}
        </Box>
      </Box>
      {details?.meeting_id &&
        <Box className="dashboard-card-meeting-container-footer">
          <Button
            className="copy-share-btn"
            onClick={copyText}
            disabled={isActive}
          >
            {copyLink}
            {copyLink == "Copied" && <CheckIcon fontSize="large" />}
          </Button>
          <Button
            className="start-meeting-btn"
            onClick={openMeeting}
            disabled={isActive}
          >
            Start Meeting
          </Button>
        </Box>
      }
    </Box>
  );
};
