import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";
import Checkbox from "@mui/material/Checkbox";
import dayjs from "dayjs";
import { getUserSettings } from "../../../services/zoom/zoomconfig";
import logob from "../../../resources/CGC-black.webp"


export default function M5ReviewInfo({ onhandleNext, onhandleBack, newMeetingObj, setNewMeetingObj, setOpen, setCreateMeeting,createdMeeting ,setupdate}) {
    const { user, getAccessTokenSilently,  } = useAuth0();
    const [showDate, setShowDate] = useState(new Date());
    const [showTime, setShowTime] = useState(new Date());
    const [time, settime] = useState(new Date())
    const [loading, setLoading] = useState(false)
    
    const zoomDomain = "https://meetingssellwithzoom-develop.herokuapp.com";

    const generateMeeting = async () => {
        setLoading(true);
        const myToken = await getAccessTokenSilently();
    
        const gettingUserSettings = await getUserSettings(myToken);
        let user_settings = {};
        if (gettingUserSettings.data){
          user_settings= {
            auto_recording: gettingUserSettings?.data?.recording?.auto_recording
          };
        }
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${myToken}`,
            comp: process.env.REACT_APP_COMP,
          },
          body: JSON.stringify({
            owner: user.sub,
            start_time: `${dayjs(newMeetingObj?.inputDate).format("YYYY-MM-DD")}T${dayjs(newMeetingObj?.inputDate).format("HH:mm:00")}Z`,
            topic: newMeetingObj?.topic,
            description: newMeetingObj?.description,
            settings: user_settings,
            type: 2,
            product_ids: {
              mainproduct: 0,
              products: newMeetingObj?.productList||[],
            },
            url_store: "",
          }),
        };
    
        await fetch(`${zoomDomain}/meeting`, options)
          .then((response) => response.json())
          .then((result) => {
            setNewMeetingObj({...newMeetingObj, idMeeting: result.meeting_id});
            setLoading(false);
            onhandleNext();            
            setCreateMeeting(createdMeeting + 1);
            setupdate(true)
          })
          .catch((error) => {
            console.log("error", error);
            setLoading(false);
          }); 
      };

    useEffect(() => {
        const dateShow = new Date(newMeetingObj?.inputDate).toString().split(" ").slice(0, 4);
        const concatDate =
            dateShow.slice(0, 3).join(" ") + ", " + dateShow.slice(3, 4).join(" ");
        setShowDate(dayjs(concatDate).isValid() ? concatDate : "");
        const dateStart = new Date(newMeetingObj?.inputDate).toLocaleTimeString("en-US", {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
        setShowTime(
            [dateStart.split(":")[0], dateStart.split(":")[1]].join(":") +
            " " +
            dateStart.split(" ")[1] +
            " " +
            Intl.DateTimeFormat().resolvedOptions().timeZone?.split("_").join(" ")
        );
       
        settime()
    }, [newMeetingObj]);


    return (
        <>
            <div>
                <h2
                    style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "#333333",
                    }}
                >
                    Review
                </h2>
            </div>


            <div style={{width:"100%", alignItems:"center", justifyContent:"center", display:"flex", flexDirection:"column"}}>
                <div
                    className="content-form review-meeting-container"
                    style={{ width: "80%", borderRadius: "5px" }}
                >
                    <Box>
                        <img                        
                            src={logob}
                            width="100px"
                            alt="workinglivelogo"
                        />
                    </Box>
                    <Typography
                        variant="h5"
                        style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            padding: "10px",
                        }}
                    >
                        Meeting Information
                    </Typography>
                    <Typography
                        variant="h4"
                        style={{
                            textAlign: "center",
                            fontWeight: "bold",
                        }}
                    >
                        {newMeetingObj?.topic}
                    </Typography>
                    <div className="select-info-meeting-container">
                        <Box className="meeting-info-header">
                            <CalendarMonthIcon fontSize="large" />
                            <Typography variant="h6">{showDate + ""}</Typography>
                            <ScheduleIcon fontSize="large" sx={{ color: "#73CCE9" }} />
                            <Typography variant="h6" color="#73CCE9">
                                {showTime + ""}
                            </Typography>
                        </Box>
                        {/* {!user["http://locahost:5000/roles"]?.includes("BASIC") &&
                    !user["http://locahost:5000/roles"]?.includes("VENTURE") && (
                    <Box className="shopping-info-container">
                        <Box className="meeting-info-check">
                        <Checkbox checked={url} />
                        <Typography variant="p" fontSize="14px">
                            Store URL- Entire Catalog
                        </Typography>
                        </Box>
                        <Box className="meeting-info-check">
                        <Checkbox checked={items.length > 0} />
                        <Typography variant="p" fontSize="14px">
                            Individual Products
                        </Typography>
                        </Box>
                        <Box className="images-info-container">
                        {items?.map((item) => {
                            if (productList?.includes(item?.id))
                            return (
                                <Box>
                                <a title={item.name}>
                                    <img
                                    className="image-info-meeting"
                                    src={item.smallThumbnailUrl}
                                    alt={item.name}
                                    width="60px"
                                    />
                                </a>
                                </Box>
                            );
                        })}
                        </Box>
                        <Box className="meeting-info-check">
                        <Checkbox checked={teamSelected.length > 0} />
                        <Typography variant="p" fontSize="14px">
                            Co-Hosts: Nancy Baker, Tom Reeve
                        </Typography>
                        </Box>
                    </Box>
                    )} */}

                        <div className="step-btn-content">
                            <button
                                className="btn-personalized"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onhandleBack();
                                }}
                            >
                                Back
                            </button>
                            <button
                                className="btn-personalized"
                                onClick={(e) => {
                                    e.preventDefault();
                                    generateMeeting();
                                    
                                }}
                                disabled={loading}
                            >
                                Create Link
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            
        </>
    )
}