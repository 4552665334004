import { Box, Typography } from "@mui/material";
import React from "react";

export const Footer = () => {
  return (
    <Box className="footer-main" sx={{ width: "100%", background: "#000", bottom: 0, position: "fixed" }}>
      <Box
        display="flex"
        justifyContent="center"
        padding="8px"
        alignItems="center"
        gap="10px"
      >
        <img alt="" src="https://i.imgur.com/ocLTWwv.png" height="30px" />
        <Typography color="#fff" fontSize={"0.85rem"}>
          WorkingLive | All rights reserved. | Copyright © 2023.
        </Typography>
      </Box>
    </Box>
  );
};
