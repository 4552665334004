import React, { useState } from 'react'
import "../../../App.css";
import "../index.scss";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import TuneIcon from "@mui/icons-material/Tune";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { CardDetailMeeting } from '../../../components/CardDetailMeeting';
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "react-modal";
import { useAuth0 } from "@auth0/auth0-react";
import deleteMeeting from '../../../services/meetings/deleteMeeting';
import Confetti from "react-confetti";


Modal.setAppElement("#root");

export default function PreviousTab({validateUserStatus, dateRange, setDateRange, filterMeetingsByUser, meetings,loadingMeetings, createdMeeting, setCreateMeeting}) {
    const { getAccessTokenSilently } = useAuth0();

    const [checkDeleteModal, setCheckDeleteModal] = useState(false);
    const [meetingIdForDelete, setMeetingIdForDelete] = useState(null);
    const [checkMeetingTopic, setCheckMeetingTopic] = useState("");
    const [successDeleteMeeting, setSuccessDeleteMeeting] = useState(false);
    const [loadingDeleteMeeting, setLoadinDeleteMeeting] = useState(false);


    const confirmDelete = (myMeetingID, myMeetingTopic) => {
        setCheckDeleteModal(!checkDeleteModal);
        setMeetingIdForDelete(myMeetingID);
        setCheckMeetingTopic(myMeetingTopic);
    };

    const sendDeleteMeeting = async (myMeetingID) => {
        setLoadinDeleteMeeting(true);
        const myToken = await getAccessTokenSilently();
        const resDelete = await deleteMeeting(
          myMeetingID,
          myToken,
          "cgcworkinglive"
        );
        setLoadinDeleteMeeting(false);
        setSuccessDeleteMeeting(true);
      };

    return (
        <>
        <TabPanel value="2" className="upcoming-meetings-container">
            <Box m="0 0 0 0" height="auto">
                <Box className="select-dates-filter-container">
                <Typography
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    variant="h5"
                    fontFamily="inherit"
                    fontWeight="bold"
                    gap="15px"
                >
                    <TuneIcon fontSize="large" />
                    Previous Meetings Filter
                </Typography>

                <LocalizationProvider
                    sx={{ width: "50%" }}
                    dateAdapter={AdapterDayjs}
                    localeText={{ start: "From", end: "Until" }}
                >
                    <DateRangePicker
                    value={dateRange}
                    id="dashboard-meetings-date-filter"
                    onChange={(newDates) => {
                        setDateRange(newDates);
                    }}
                    renderInput={(startProps, endProps) => (
                        <React.Fragment>
                        <TextField
                            style={{ height: "10px !important" }}
                            {...startProps}
                        />
                        <Box sx={{ mx: 2 }}> to </Box>
                        <TextField {...endProps} />
                        </React.Fragment>
                    )}
                    />
                </LocalizationProvider>
                <Button
                    className="invite-meeting-tab"
                    style={{ marginLeft: "5px", height: "30px" }}
                    onClick={filterMeetingsByUser}
                >
                    Apply
                </Button>
                </Box>
                {!loadingMeetings &&
                meetings?.filter((val, index)=>val?.status=="ended")?.map((meet, index) => {
                    const dateStart = new Date(
                    meet?.start_time
                    ).toLocaleTimeString("en-US", {
                    timeZone: meet?.timezone,
                    });
                    return (
                    <CardDetailMeeting
                        key={index}
                        type="previous"
                        onClick={() =>
                        confirmDelete(meet?.id, meet?.topic)
                        }
                        startDate={new Date(
                        meet?.start_time
                        ).toDateString()}
                        meetingTopic={meet?.topic}
                        startTime={
                        [
                            dateStart.split(":")[0],
                            dateStart.split(":")[1],
                        ].join(":") +
                        " " +
                        dateStart.split(" ")[1] +
                        " " +
                        meet?.timezone?.split("_").join(" ")
                        }
                        meetingID={
                        meet?.join_url
                            ?.split("?")[0]
                            .split("/")
                            .reverse()[0]
                        }
                    ></CardDetailMeeting>
                    );
                })}
                {loadingMeetings && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="60vh"
                    flexDirection="column"
                >
                    <CircularProgress
                    style={{
                        color: "#00000",
                        width: "70px",
                        height: "70px",
                    }}
                    />
                    <Typography variant="h6" fontWeight="bold">
                    Loading Previous Meetings...
                    </Typography>
                </Box>
                )}
            </Box>
        </TabPanel>
        {/*  Confirm Delete  */}
      <div className="App">
        <Modal
          isOpen={checkDeleteModal}
          // onRequestClose={() => setConfirmModal(false)}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max-delete",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          {!successDeleteMeeting && (
            <div>
              <div>
                <h2
                  style={{
                    fontWeight: "bold",
                    color: "#202020",
                    padding: "30px 30px 15px 30px",
                    textAlign: "center",
                  }}
                >
                  Delete Meeting
                </h2>
              </div>
              <div className="content-form">
                <Box className="content-form-header">
                  <Typography
                    variant="h5"
                    fontWeight="500"
                    textAlign="center"
                    padding="0px 10px 10px 10px"
                  >
                    Are you sure you want to delete meeting {meetingIdForDelete}{" "}
                    with topic "{checkMeetingTopic}"?
                  </Typography>
                </Box>
                <div className="content-btn-down">
                  <Button
                    className="btn-width-start-delete"
                    onClick={(e) => {
                      sendDeleteMeeting(meetingIdForDelete);
                    }}
                  >
                    {loadingDeleteMeeting ? (
                      <CircularProgress sx={{ color: "#FFFFFF" }} />
                    ) : (
                      "DELETE"
                    )}
                  </Button>
                  <Button
                    className="btn-width-start-exit"
                    onClick={(e) => {
                      setCheckDeleteModal(false);
                    }}
                  >
                    CANCEL
                  </Button>
                </div>
              </div>
            </div>
          )}
          {successDeleteMeeting && (
            <>
              <Confetti numberOfPieces={70} tweenDuration={50}></Confetti>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div>
                  <h2
                    style={{
                      fontWeight: "bold",
                      color: "#202020",
                      padding: "0px 30px 10px 30px",
                      textAlign: "center",
                    }}
                  >
                    Meeting has been deleted! 🎉
                  </h2>
                </div>
                <div className="content-form">
                  <Box className="content-form-header">
                    <Typography
                      variant="h5"
                      fontWeight="500"
                      textAlign="center"
                      padding="0px 20px 20px 20px"
                    >
                      The meeting topic "
                      <span style={{ fontWeight: "bold" }}>
                        {checkMeetingTopic}
                      </span>
                      " with ID{" "}
                      <span style={{ fontWeight: "bold" }}>
                        #{meetingIdForDelete}
                      </span>{" "}
                      has been deleted, which means it won't show up in your
                      past meetings.
                    </Typography>
                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        className="btn-width-start-exit"
                        onClick={(e) => {
                          setCreateMeeting(createdMeeting + 1);
                          setCheckDeleteModal(false);
                          setSuccessDeleteMeeting(false);
                        }}
                      >
                        ACCEPT
                      </Button>
                    </Box>
                  </Box>
                </div>
              </div>
            </>
          )}

          <button
            className="close-modal"
            onClick={() => {
              setCheckDeleteModal(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button>
        </Modal>
      </div>
    </>
    )
}
