import { Box, Typography } from "@mui/material";
import React from "react";

export default class CollectJSSection extends React.Component {
  // Created the 3 divs for the CollectJS iframes
  render() {
    return (
      <React.Fragment>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Typography variant="p" fontWeight="600" padding="10px 0px" style={{ fontSize: "1rem" }}>
              Card Number
            </Typography>
            <div id="ccnumber" />
          </Box>
          <Box sx={{ width: "100%", display: "flex", gap: "8px" }}>
            <Box sx={{ width: "50%" }}>
              <Typography variant="p" fontWeight="600" padding="10px 0px" style={{ fontSize: "1rem" }}>
                Valid Thru
              </Typography>
              <div id="ccexp" />
            </Box>
            <Box sx={{ width: "50%" }}>
              <Typography variant="p" fontWeight="600" padding="10px 0px" style={{ fontSize: "1rem" }}>
                CVV
              </Typography>
              <div id="cvv" />
            </Box>
          </Box>
        </Box>
      </React.Fragment>
    );
  }
}
