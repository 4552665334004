import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, TextField, Typography, MenuItem } from "@mui/material";
import CollectJSSection from "./CollectJsSection";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import { useCreateOrder } from "../../hooks/useOrder";
import Card from "react-credit-cards";

class CardPaymentMethod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: this.props.name,
      lastName: this.props.name,
      amount: this.props.amount,
      isSubmitting: false,
      alertMessage: "",
      isLoading: false,
    };
    this.setState = this.setState.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.finishSubmit = this.finishSubmit.bind(this);
    this.generateOrder = this.generateOrder.bind(this);
  }

  componentDidMount() {
    window.CollectJS.configure({
      paymentSelector: "#payButton",
      variant: "inline",
      styleSniffer: true,
      callback: (token) => {
        this.setState({ isSubmitting: true });
        this.finishSubmit(token);
      },
      fields: {
        ccnumber: {
          placeholder: "4111 1111 1111 1111",
          selector: "#ccnumber",
        },
        ccexp: {
          placeholder: "02 / 26",
          selector: "#ccexp",
        },
        cvv: {
          placeholder: "1**",
          selector: "#cvv",
        },
      },
      validationCallback: (field, status, message) => {
        console.log(
          `Detalles: Stados ${status} Mensaje: ${message} y Field: ${field}`
        );
        if (status) {
          if (field == "ccnumber") {
            console.log("Error en el numero de Tarjeta");
          }
          if (field == "ccexp") {
            console.log("Error fecha de expiracion");
          }
          if (field == "ccv") {
            console.log("error CCV");
          }
        }
      },
    });
  }

  async generateOrder(payload) {
    this.setState({ isLoading: true });
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
    fetch(`https://backzoom.herokuapp.com/create_order`, options)
      .then((res) => res.json())
      .then((res) => {
        this.setState({ isLoading: false });
        this.props.onClick();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log("Error");
      });
  }

  finishSubmit(response) {
    this.setState({ isSubmitting: true });
    this.setState({ isLoading: true });
    const { isSubmitting, alertMessage, ...formData } = { ...this.state };
    formData.token = response.token;
    const payload = {
      token: response.token,
      dataOrder: {
        paymentMethod: "Credit Card",
        createDate: new Date(),
      },
    };
    this.setState({
      isSubmitting: false,
    });
    this.generateOrder(payload);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    console.log("Enviando el Pago:", this.setState.isSubmitting);
    window.CollectJS.startPaymentRequest();
  }

  render() {
    return (
      <div className="App">
        <Box className="payment-form-container">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              padding: "10px 0px",
              width: "100%",
              justifyContent: "space-between",
              background: "#FFFFFF",
              padding: "10px",
              borderRadius: "4px"
            }}
          >
            <Card
              number={4111111111111111}
              name="William Venegas"
              expiry="02/26"
              cvc="123"
              focused={true}
              //callback={this.handleCallback}
            />
          </Box>

          {this.state.alertMessage && (
            <div className="alert">{this.state.alertMessage}</div>
          )}
          <Box
          style={{
            marginTop: "30px",
            padding: "10px"
          }}
        >
          <Typography
            textAlign="left"
            variant="h4"
            fontWeight="bold"
            fontFamily="inherit"
          >
            Billing information
          </Typography>
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginTop: "15px",
              gap: "10px",
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              size="small"
              label="ADDRESS LINE 1"
              special="first"
              name="address1"
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              size="small"
              label="ADDRESS LINE 2"
              special="first"
              name="address2"
              sx={{ gridColumn: "span 4" }}
            />
            <Box style={{ width: "100%", display: "flex", gap: "10px" }}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                size="small"
                label="CITY"
                special="first"
                name="city"
                sx={{ width: "25%" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                size="small"
                label="STATE/PROVINCE"
                special="first"
                name="state"
                sx={{ width: "25%" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                size="small"
                label="ZIP/POSTAL"
                special="first"
                name="zip"
                sx={{ width: "25%" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                select
                size="small"
                label="COUNTRY*"
                special="first"
                name="country"
                sx={{ width: "25%" }}
                className="field-input"
              >
                <MenuItem key={1} value="US">
                  US
                </MenuItem>
                <MenuItem key={2} value="CA">
                  CA
                </MenuItem>
              </TextField>
            </Box>
          </Box>
        </Box>
          <form
            onSubmit={this.handleSubmit}
            style={{ padding: "0px 10px 10px 10px" }}
          >
            <CollectJSSection />
            <Box className="container-btn-edit-cards">
              <button
                id="payButton"
                type="submit"
                disabled={this.state.isLoading}
                className="start-meeting-tab edit-btn-card"
              >
                {this.state.isLoading ? (
                  <Typography variant="h5" fontWeight="bold">
                    Loading...
                  </Typography>
                ) : (
                  <Typography variant="h5" fontWeight="bold">
                    Edit
                  </Typography>
                )}
              </button>
              <button
                id="payButton"
                type="submit"
                disabled={this.state.isLoading}
                className="invite-meeting-tab edit-btn-card"
              >
                {this.state.isLoading ? (
                  <Typography variant="h5" fontWeight="bold">
                    Loading...
                  </Typography>
                ) : (
                  <Typography variant="h5" fontWeight="bold">
                    Delete
                  </Typography>
                )}
              </button>
            </Box>
          </form>
        </Box>
      </div>
    );
  }
}

export default CardPaymentMethod;
