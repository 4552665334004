import React from "react";
import { Box, Typography } from "@mui/material";
import Navbar from "../../components/navbar/Navbar";
import { Footer } from "../../components/Footer/Footer";
import { Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { withAuthenticationRequired } from "@auth0/auth0-react";

const Verify = () => {
  const { user, logout } = useAuth0();
  const handleLogout = () => {
    logout({ logoutParams: { returnto: window.location.origin } });
  };
  return (
    <Box style={{ background: "#fbf3ef" }}>
      <Navbar></Navbar>
      <Box className="verify-email-container">
        <Box className="card-verify-email">
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <img 
              src="https://cdn-icons-png.flaticon.com/512/2989/2989993.png"
              width="100px"
            />
          </Box>

          <Typography
            variant="h5"
            fontFamily="inherit"
            fontWeight="bold"
            textAlign="center"
          >
            Verify Your Email Address
          </Typography>
          <Box className="container-verify-text">
            <Typography variant="h6" textAlign="center">
              To continue using <span style={{ fontWeight: "bold" }}>CGC</span>,
              please verify your email address or contact the support team:
            </Typography>
            <Typography
              variant="h5"
              fontWeight="bold"
              textAlign="center"
              paddingTop="20px"
            >
              {user?.email}
            </Typography>
          </Box>
          <Box className="container-verify-btn">
            <Button className="start-meeting-tab" onClick={handleLogout}>
              Log in
            </Button>
          </Box>
        </Box>
      </Box>
      <Footer></Footer>
    </Box>
  );
};

export default withAuthenticationRequired(Verify);
