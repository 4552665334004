import React, { useEffect, useState } from 'react'
import "../../../App.css";
import "../index.scss";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from "@mui/material";
import { ContentMeetings } from '../../../components/ContentMeetings';
import Typography from "@mui/material/Typography";
import TuneIcon from "@mui/icons-material/Tune";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { CardDetailMeeting } from '../../../components/CardDetailMeeting';
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "react-modal";
import Stepper from "@mui/material/Stepper";
import { useAuth0 } from "@auth0/auth0-react";

import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import StepConnector, { stepConnectorClasses, } from "@mui/material/StepConnector";
import SettingsIcon from "@mui/icons-material/Settings";
import Check from "@mui/icons-material/Check";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import YoutubeSearchedForIcon from "@mui/icons-material/YoutubeSearchedFor";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import M1MeetingInfo from '../newMeetingModal/m1MeetingInfo';
import M5ReviewInfo from '../newMeetingModal/m5ReviewInfo';
import M6InviteLink from '../newMeetingModal/m6InviteLink';
import deleteMeeting from '../../../services/meetings/deleteMeeting';
import Confetti from "react-confetti";
import "./upcomingTab.css"
import dayjs from 'dayjs';
import { meetingInfoBack} from '../../../services/meetings/meetingInfo';
import Me5ReviewInfo from '../editMeetingModal/me5ReviewInfo';

/**** UTILS FOR STEPPER *****/
// Utils
const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
        color: "#784af4",
    }),
    "& .QontoStepIcon-completedIcon": {
        color: "#784af4",
        zIndex: 1,
        fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "#784af4",
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: "#779F60 !important",
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: "#779F60 !important",
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor: "#DEDEDE",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
        backgroundColor: "#779F60",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
        backgroundColor: "#779F60 !important",
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <SettingsIcon fontSize="large" />,
        2: <DevicesOtherIcon fontSize="large" />,
        3: <ReduceCapacityIcon fontSize="large" />,
        4: <YoutubeSearchedForIcon fontSize="large" />,
        5: <InsertInvitationIcon fontSize="large" />,
    };

    return (
        <ColorlibStepIconRoot
            ownerState={{ completed, active }}
            className={className}
        >
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};
/****************************/



Modal.setAppElement("#root");

const basicSteps = [
    {label:"MEETING SET UP", step:0, value:"meet_set_up"},
    {label:"REVIEW CRITERIA", step:1, value:"review_criteria"},
    {label:"CREATE INVITE LINK", step:2, value:"create_link"},
];

export default function UpcomingTab({ validateUserStatus, dateRange, setDateRange, filterMeetingsByUser, meetings, loadingMeetings, setCreateMeeting,createdMeeting }) {
    const { getAccessTokenSilently } = useAuth0();

    const [openModal, setOpenModal] = useState(false);
    const [invite, setInvite] = useState(false);
    const [activeStep, setactiveStep] = useState(0);
    const [newMeetingObj, setNewMeetingObj] = useState({inputDate:new Date(), inputTime: new Date()});
    const [currentContent, setCurrentContent] = useState("meet_set_up")
    const [checkDeleteModal, setCheckDeleteModal] = useState(false);
    const [successDeleteMeeting, setSuccessDeleteMeeting] = useState(false);
    const [checkMeetingTopic, setCheckMeetingTopic] = useState("");
    const [meetingIdForDelete, setMeetingIdForDelete] = useState(null);
    const [loadingDeleteMeeting, setLoadinDeleteMeeting] = useState(false);
    const [update, setupdate] = useState(false)
    const [openEditMeeting, setopenEditMeeting] = useState(false)
    
    const handleMeeting = () => {
        setOpenModal(true);
    };

    const onhandleNext = () => {
        setactiveStep(activeStep+1)
    }
    const onhandleBack = () => {
        setactiveStep(activeStep-1)
    }

    useEffect(() => {
      if(!openModal){
        if(newMeetingObj?.idMeeting&&update){
            window.location.reload();
        }
        setupdate(false)
        setNewMeetingObj({inputDate:new Date(), inputTime: new Date()});
        setactiveStep(0)
      }
    }, [openModal])
    

    useEffect(() => {
        setCurrentContent(basicSteps?.find((val, index)=>val?.step===activeStep)?.value)
    }, [activeStep]);
    
  
    const handleInvite = () => {
        setInvite(true);
    };

    const handleEditMeeting = async (myMeetingID) =>{
        const resMeetInfo = await meetingInfoBack(myMeetingID);
        let fecha = resMeetInfo?.data?.start_time?.replace("T", " ").replace("Z", "")
        if(resMeetInfo?.data){
            setNewMeetingObj({...resMeetInfo?.data, inputDate:dayjs(fecha), idMeeting: myMeetingID});
            setOpenModal(true); 
            setopenEditMeeting(true);
        }
    }

    const confirmDelete = (myMeetingID, myMeetingTopic) => {
        setCheckDeleteModal(!checkDeleteModal);
        setMeetingIdForDelete(myMeetingID);
        setCheckMeetingTopic(myMeetingTopic);
    };

    const sendDeleteMeeting = async (myMeetingID) => {
        setLoadinDeleteMeeting(true);
        const myToken = await getAccessTokenSilently();
        const resDelete = await deleteMeeting(
          myMeetingID,
          myToken,
          "cgcworkinglive"
        );
        setLoadinDeleteMeeting(false);
        setSuccessDeleteMeeting(true);
      };

    return <>
        <TabPanel value="1" className="upcoming-meetings-container">
            <Box m="0 0 0 0" height="auto">
                <ContentMeetings
                    onClick={handleMeeting}
                    onInvite={handleInvite}
                    isActive={validateUserStatus}
                ></ContentMeetings>
                <Box className="select-dates-filter-container">
                    <Typography
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        variant="h5"
                        fontFamily="inherit"
                        fontWeight="bold"
                        gap="15px"
                    >
                        <TuneIcon fontSize="large" />
                        Upcoming Meetings Filter
                    </Typography>

                    <LocalizationProvider
                        sx={{ width: "50%" }}
                        dateAdapter={AdapterDayjs}
                        localeText={{ start: "From", end: "Until" }}
                    >
                        <DateRangePicker
                            value={dateRange}
                            id="dashboard-meetings-date-filter"
                            onChange={(newDates) => {
                                setDateRange(newDates);
                            }}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <TextField
                                        style={{ height: "10px !important" }}
                                        {...startProps}
                                    />
                                    <Box sx={{ mx: 2 }}> to </Box>
                                    <TextField {...endProps} />
                                </React.Fragment>
                            )}
                        />
                    </LocalizationProvider>
                    <Button
                        className="invite-meeting-tab"
                        style={{ marginLeft: "5px", height: "30px" }}
                        onClick={filterMeetingsByUser}
                    >
                        Apply
                    </Button>
                </Box>
                {!loadingMeetings &&
                    meetings?.filter((val, index)=>val?.status==="created"||val?.status==="started")?.map((meet, index) => {
                        let hour = meet?.start_time?.replace("T", " ");
                        hour = hour.replace("Z", "")
                        return (
                            <CardDetailMeeting
                                key={index}
                                onClick={() =>confirmDelete(meet?.id, meet?.topic)}
                                onhandleEdit={() =>handleEditMeeting(meet?.id)}
                                meetingStatus={meet?.status}
                                startDate={new Date(
                                    meet?.start_time
                                ).toDateString()}
                                meetingTopic={meet?.topic}
                                meetingDescrip={meet?.description}
                                startTime={dayjs(hour).format("hh:mm A")+" "+meet?.timezone}
                                meetingID={
                                    meet?.join_url
                                        ?.split("?")[0]
                                        .split("/")
                                        .reverse()[0]
                                }
                            ></CardDetailMeeting>
                        );
                    })}
                {loadingMeetings && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="60vh"
                        flexDirection="column"
                    >
                        <CircularProgress
                            style={{
                                color: "#00000",
                                width: "70px",
                                height: "70px",
                            }}
                        />
                        <Typography variant="h6" fontWeight="bold">
                            Loading Upcoming Meetings...
                        </Typography>
                    </Box>
                )}
            </Box>
        </TabPanel>
        {/* Modal for New Meeting */}
        <div className="App">
            <Modal
                isOpen={openModal}
                overlayClassName={{
                    base: "overlay-base",
                    afterOpen: "overlay-after",
                    beforeClose: "overlay-before",
                }}
                className={{
                    base: "content-base",
                    afterOpen: "content-after-max",
                    beforeClose: "content-before",
                }}
                closeTimeoutMS={500}
            >
                <div className="first-step-content">                                 
                    <div style={{paddingTop:"10px"}}>
                        <Stepper
                            alternativeLabel
                            activeStep={activeStep}
                            connector={<ColorlibConnector />}
                        >
                            {basicSteps?.map((val, index) => (
                                <Step key={index}>
                                    <StepLabel className='green-stepper' StepIconComponent={ColorlibStepIcon}>
                                        {val?.label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                    {currentContent==="meet_set_up"&& 
                        <M1MeetingInfo 
                            onhandleNext={onhandleNext} 
                            onhandleBack={onhandleBack}
                            newMeetingObj={newMeetingObj}
                            setNewMeetingObj={setNewMeetingObj}
                            setOpen={setOpenModal}
                        />}
                    {currentContent==="review_criteria"? 
                        openEditMeeting ?
                        <Me5ReviewInfo 
                            onhandleNext={onhandleNext} 
                            onhandleBack={onhandleBack}
                            newMeetingObj={newMeetingObj}
                            setNewMeetingObj={setNewMeetingObj}
                            setOpen={setOpenModal}
                            setCreateMeeting={setCreateMeeting}
                            createdMeeting={createdMeeting}
                            setupdate={setupdate}
                        />
                        :<M5ReviewInfo 
                            onhandleNext={onhandleNext} 
                            onhandleBack={onhandleBack}
                            newMeetingObj={newMeetingObj}
                            setNewMeetingObj={setNewMeetingObj}
                            setOpen={setOpenModal}
                            setCreateMeeting={setCreateMeeting}
                            createdMeeting={createdMeeting}
                            setupdate={setupdate}
                        />
                        :<></>}
                    {currentContent==="create_link"&& 
                        <M6InviteLink 
                            onhandleNext={onhandleNext} 
                            onhandleBack={onhandleBack}
                            newMeetingObj={newMeetingObj}
                            setNewMeetingObj={setNewMeetingObj}
                            setOpen={setOpenModal}
                            setCreateMeeting={setCreateMeeting}
                            createdMeeting={createdMeeting}
                        />}
                    

                </div>

                <button
                    className="close-modal"
                    onClick={() => {
                        setOpenModal(false);
                    }}
                >
                    <a className="text-close-modal">X</a>
                </button>
            </Modal>
        </div>


        {/*  Confirm Delete  */}
        <div className="App">
            <Modal
            isOpen={checkDeleteModal}
            // onRequestClose={() => setConfirmModal(false)}
            overlayClassName={{
                base: "overlay-base",
                afterOpen: "overlay-after",
                beforeClose: "overlay-before",
            }}
            className={{
                base: "content-base",
                afterOpen: "content-after-max-delete",
                beforeClose: "content-before",
            }}
            closeTimeoutMS={500}
            >
            {!successDeleteMeeting && (
                <div>
                <div>
                    <h2
                    style={{
                        fontWeight: "bold",
                        color: "#202020",
                        padding: "30px 30px 15px 30px",
                        textAlign: "center",
                    }}
                    >
                    Delete Meeting
                    </h2>
                </div>
                <div className="content-form">
                    <Box className="content-form-header">
                    <Typography
                        variant="h5"
                        fontWeight="500"
                        textAlign="center"
                        padding="0px 10px 10px 10px"
                    >
                        Are you sure you want to delete meeting {meetingIdForDelete}{" "}
                        with topic "{checkMeetingTopic}"?
                    </Typography>
                    </Box>
                    <div className="content-btn-down">
                    <Button
                        className="btn-width-start-delete"
                        disabled={loadingDeleteMeeting}
                        onClick={(e) => {
                        sendDeleteMeeting(meetingIdForDelete);
                        }}
                    >
                        {loadingDeleteMeeting ? (
                        <CircularProgress sx={{ color: "#FFFFFF" }} />
                        ) : (
                        "DELETE"
                        )}
                    </Button>
                    <Button
                        className="btn-width-start-exit"
                        onClick={(e) => {
                        setCheckDeleteModal(false);
                        }}
                    >
                        CANCEL
                    </Button>
                    </div>
                </div>
                </div>
            )}
            {successDeleteMeeting && (
                <>
                <Confetti numberOfPieces={70} tweenDuration={50}></Confetti>
                <div
                    style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    }}
                >
                    <div>
                    <h2
                        style={{
                        fontWeight: "bold",
                        color: "#202020",
                        padding: "0px 30px 10px 30px",
                        textAlign: "center",
                        }}
                    >
                        Meeting has been deleted! 🎉
                    </h2>
                    </div>
                    <div className="content-form">
                    <Box className="content-form-header">
                        <Typography
                        variant="h5"
                        fontWeight="500"
                        textAlign="center"
                        padding="0px 20px 20px 20px"
                        >
                        The meeting topic "
                        <span style={{ fontWeight: "bold" }}>
                            {checkMeetingTopic}
                        </span>
                        " with ID{" "}
                        <span style={{ fontWeight: "bold" }}>
                            #{meetingIdForDelete}
                        </span>{" "}
                        has been deleted, which means it won't show up in your
                        past meetings.
                        </Typography>
                        <Box
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        >
                        <Button
                            className="btn-width-start-exit"
                            onClick={(e) => {
                            setCreateMeeting(createdMeeting + 1);
                            setCheckDeleteModal(false);
                            setSuccessDeleteMeeting(false);
                            }}
                        >
                            ACCEPT
                        </Button>
                        </Box>
                    </Box>
                    </div>
                </div>
                </>
            )}

            <button
                className="close-modal"
                onClick={() => {
                setCheckDeleteModal(false);
                }}
            >
                <a className="text-close-modal">X</a>
            </button>
            </Modal>
        </div>
        
    </>
}
