import { Box, Button, Typography } from "@mui/material";
import React from "react";
import Modal from "react-modal";


Modal.setAppElement("#root");
export const ModalConfirm = ({
  openModal,
  setOpenModal,
  actionFunc,
  titleModal,
  subTitleModal,
}) => {
  return (
    <div className="App">
      <Modal
        isOpen={openModal}
        // onRequestClose={() => setConfirmModal(false)}
        overlayClassName={{
          base: "overlay-base",
          afterOpen: "overlay-after",
          beforeClose: "overlay-before",
        }}
        className={{
          base: "content-base",
          afterOpen: "content-after-max-delete",
          beforeClose: "content-before",
        }}
        closeTimeoutMS={500}
      >
        <div>
          <div>
            <h2
              style={{
                fontWeight: "bold",
                color: "#202020",
                padding: "30px 30px 15px 30px",
                textAlign: "center",
              }}
            >
              {titleModal}
            </h2>
          </div>
          <div className="content-form">
            <Box className="content-form-header">
              <Typography
                variant="h5"
                fontWeight="500"
                textAlign="center"
                padding="0px 10px 10px 10px"
              >
                {subTitleModal}
              </Typography>
            </Box>
            <div className="content-btn-down">
              <Button className="btn-width-start-delete" onClick={actionFunc}>
                CONFIRM
              </Button>
              <Button
                className="btn-width-start-exit"
                onClick={(e) => {
                  setOpenModal(false);
                }}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </div>

        <button
          className="close-modal"
          onClick={() => {
            setOpenModal(false);
          }}
        >
          <a className="text-close-modal">X</a>
        </button>
      </Modal>
    </div>
  );
};
