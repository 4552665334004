export const meetingInfo = async (token, meetingNumber) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      comp: process.env.REACT_APP_COMP,
    },
  };

  const reqMeetingInfo = await fetch(
    `${process.env.REACT_APP_MEETINGS}/meeting/${meetingNumber}`,
    options
  );

  const resMeetingInfo = await reqMeetingInfo.json();

  if (!reqMeetingInfo.ok) {
    return { error: "Error Getting Meeting Info" };
  }

  if (reqMeetingInfo.ok) {
    return { data: resMeetingInfo };
  }
};

export const meetingInfoBack = async (meetingNumber) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const reqMeetingInfoBack = await fetch(
    `${process.env.REACT_APP_BACKZOOM}/meetingDB/${meetingNumber}?tenant=cgcworkinglive`,
    options
  );

  const resMeetingInfoBack = await reqMeetingInfoBack.json();

  if (!reqMeetingInfoBack.ok) {
    return { error: "Error Getting Info Back Zoom" };
  }

  if (reqMeetingInfoBack.ok) {
    return { data: resMeetingInfoBack };
  }
};
