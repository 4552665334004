import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import CircularProgress from "@mui/material/CircularProgress";
import "./App.css";
/* import "./zoom.css"; */
//import { ZoomMtg } from "@zoomus/websdk";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Checkout from "./components/Checkout";
import TopHeader from "./components/TopHeader";
import { Box,  } from "@mui/material";
import io from "socket.io-client";
import ShareMeeting from "./components/ShareMeeting";
import getProducts from "./services/ecwid/products";
import userInfo from "./services/user/userInfo";
import { meetingInfoBack, meetingInfo } from "./services/meetings/meetingInfo";
import NotificationUpdate from "./components/Notification";
import { replaceObjectById } from "./utils/replaceProducts/replaceProducts";
import SharedInvite from "./components/Invite";
import logob from "./resources/CGC-black.webp"
import { useNavigate } from "react-router-dom";
import ZoomEmbedded from "./zoom/App-New";



Modal.setAppElement("#root");

let tokenVerify;

function WKMeeting() {
  // ID
  let { meetingNumber } = useParams();

  const [socket, setSocket] = useState(null);
  const [iframe, setIframe] = useState(null);
  const [active, setActive] = useState(false);
  const [choose, setChosse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [items, setitems] = useState([]);
  const [userName, setUserName] = useState(null);
  const [email, setEmail] = useState(null);
  const [role, setRole] = useState(null);
  const [cart, setCart] = useState(
    localStorage.getItem(`cart_${meetingNumber}`)
      ? JSON.parse(localStorage.getItem(`cart_${meetingNumber}`))
      : []
  );
  const [openShareModal, setOpenShareModal] = useState(false);
  const [totalCart, setTotalCart] = useState(0);
  const [meetingsDetails, setMeetingDetails] = useState(null);
  const [productsIDs, setProductsIDS] = useState([]);
  const [fullName, setFullName] = useState(null);
  const [successOrder, setSuccessOrder] = useState(false);
  const [affiliateID, setAffiliateID] = useState("");
  const [totalProducts, setTotalProducts] = useState([]);
  // View Depending Type of Subscription
  const [showSingleSub, setShowSingleSub] = useState(true);
  // Listen PostMSG
  const { user, getAccessTokenSilently, isLoading } = useAuth0();
  const [getBasicRole, setGetBasicRole] = useState(null);

  let urlMeeting = params.loadIframe;

  let leaveUrl = "https://cgcworkinglive.vercel.app/";

  let subtitle;
  // Modal
  const [openModal, setOpenModal] = useState(true);

  // Zoom Credentials Form
  const [inputEmail, setInputEmail] = useState(
    sessionStorage.getItem("userEmail")
  );
  const [inputUserName, setInputUserName] = useState(
    sessionStorage.getItem("userName")
  );

  // Zoom Host Options
  const [viewHostOptions, setViewHostOptions] = useState(false);

  // Zoom Ready Presentation
  const [viewZoomRP, setViewZoomRP] = useState(false);
  // Create a Bundle
  const [viewCreateBundle, setViewCreateBundle] = useState(false);
  // Enroll Now
  const [viewEnrollNow, setViewEnrollNow] = useState(false);

  const [showCheckOut, setShowCheckOut] = useState(false);
  const [showIframeBBR, setshowIframeBBR] = useState(false);

  const [showNotification, setShowNotification] = useState(false);
  const [itemId, setitemId] = useState([]);
  const [globalIdUser, setGlobalIDUser] = useState(null);

  const [renderIframe, setRenderIframe] = useState(false);
  const [showProducts, setShowProducts] = useState([]);
  const [productsForUpdate, setProductsForUpdate] = useState([]);
  
  const [tokenTemp, settokenTemp] = useState(undefined)

  // Verify
  const [isVerifiying, setIsVerifiying] = useState(true);

  useEffect(() => {
    localStorage.getItem("sax") &&
    sessionStorage.setItem("sax",localStorage.getItem("sax"))
    localStorage.getItem("sb") &&
    sessionStorage.setItem("sb",localStorage.getItem("sb"))
    localStorage.removeItem("sb");
    localStorage.removeItem("sax");
    return () => {}
  }, [])

  
  
  const saveCredentials = () => {
    sessionStorage.setItem("userEmail", inputEmail);
    sessionStorage.setItem("userName", inputUserName);
    console.log("Session Saved");
  };

  const sendNotification = () => {
    setShowNotification(true);
    const timeout = setTimeout(() => {
      setShowNotification(false);
    }, 5000);
    return () => clearTimeout(timeout);
  };

  const updateProductatatus = (id, status) => {
    let tempProductForUpdate = [];
    productsForUpdate?.forEach(function (element) {
      element.id == id
        ? tempProductForUpdate.push({ id: element.id, status: status })
        : tempProductForUpdate.push(element);
    });
    setProductsForUpdate(tempProductForUpdate);
  };

  const sendProductsForUpdate = () => {
    const filteredProducts = productsForUpdate.filter(
      (prod) => prod.status === true
    );
    const productsAllowed = filteredProducts?.map((prod) => prod.id);
    emitSelectProducts(productsAllowed);
  };

  setTimeout(function () {
    setIsVerifiying(false);
  }, 3000);

  const clearCart = () => {
    setCart([]);
    setitemId([]);
  };

  useEffect(() => {
    const loadProductsInfo = async () => {
      setLoading(true);
      const myProducts = await getProducts(process.env.REACT_APP_ECWID_TOKEN);
      let arrayItems = [];
      setTotalProducts([]);
      setProductsForUpdate([]);
      myProducts.data.products.forEach((element) => {
        if (element.name) {
          setTotalProducts((totalProducts) => [...totalProducts, element]);
          setProductsForUpdate((productsForUpdate) => [
            ...productsForUpdate,
            {
              id: element.id,
              status: productsIDs.includes(element.id) ? true : false,
            },
          ]);
          if (productsIDs.includes(element.id)) {
            arrayItems.push(element);
            // Esta parte hay que borrarla en el futuro
          } else {
            arrayItems.push(element);
          }
        }
      });
      setLoading(false);
      setitems(arrayItems);
    };
    productsIDs && loadProductsInfo();
  }, [productsIDs]);

  useEffect(() => {
    
    const getUserInfo = async () => {
      try {
        //const myToken = await getAccessTokenSilently();
        /* console.log("enter")
         const myToken2 = await getAccessTokenSilently();
        console.log("hey",myToken2)  */
        let mytoken = sessionStorage.getItem("sax");
        let sub =  sessionStorage.getItem("sb");
        if(user){
          mytoken = await getAccessTokenSilently();
          sub = user?.sub;
        }
        const gettingUserInfo = await userInfo(sub, mytoken);
        if (gettingUserInfo.data) {
          setEmail(gettingUserInfo.data.email);
          setInputEmail(gettingUserInfo.data.email);
          setInputUserName(gettingUserInfo.data.username);
          setFullName(gettingUserInfo.data.name);
        }
      } catch (error) {
        console.log("e", error)
      }
      
    };
   /*  getUserInfo(); */
    (user?.sub || tokenTemp) &&  getUserInfo();
  }, [user, tokenTemp]);

/*  useEffect(() => {
    subTemp &&
      localStorage.removeItem("sb");
  }, [subTemp])

  useEffect(() => {
    tokenTemp &&
    localStorage.removeItem("sax");
  }, [tokenTemp]) */
   
  
  useEffect(() => {
    const loadingMeetings = async () => {
      try {
        const myToken = sessionStorage.getItem("sax");
        settokenTemp(myToken); 
       /*  localStorage.removeItem("sax");
        localStorage.removeItem("sb"); */
        //const myToken = await getAccessTokenSilently();
        tokenVerify = myToken;
        const gettingMeetingInfo = await meetingInfo(myToken, meetingNumber);
        if (gettingMeetingInfo.data) {
          setAffiliateID(gettingMeetingInfo.data.id_user);
          setMeetingDetails(gettingMeetingInfo.data);
          
        }
      } catch (error) {
        console.log("Error: ", error);
      }

      const gettingMeetingInfoBack = await meetingInfoBack(meetingNumber);
      if (gettingMeetingInfoBack.data) {
        setGlobalIDUser(gettingMeetingInfoBack.data.id_user);
        const productsResponse =
          gettingMeetingInfoBack.data.product_ids.products;
        productsResponse?.map((product) =>
          setProductsIDS((productsIDs) => [...productsIDs, parseInt(product)])
        );
      }
    };
    loadingMeetings();
  }, [meetingNumber]);

  useEffect(() => {
    const newSocket = io("https://swzzockets-deade9becb32.herokuapp.com", {
      path: "/ws/sockets/",
      transports: ["websocket"],
      autoConnect: false,
      query: {
        tenant: process.env.REACT_APP_COMP,
        meetingid: meetingNumber,
      },
    }); // Establece la conexión con el servidor Socket.io
    setSocket(newSocket);
    newSocket.connect();
    console.log("Socket After Connect: ", newSocket);

    newSocket.on("connect", () => {
      console.log("Conectado al servidor Socket.io");
    });

    newSocket.on("getproducts", (data, notification) => {
      console.log("Respuesta del servidor:", data, notification);
      setShowProducts(data.products);
      notification && sendNotification();
    });

    newSocket.on("updateproducts", (data) => {
      console.log("Respuesta del Update:", data);
    });

    newSocket.on("newchange", (data) => {
      const allNewProducts = replaceObjectById(setTotalProducts, data);
      const meetingNewProducts = replaceObjectById(showProducts, data);
      setTotalProducts(allNewProducts);
      setShowProducts(meetingNewProducts);
      sendNotification();
      console.log("Respuesta del Update:", data);
    });

    setGetBasicRole(
      user
        ? user["http://locahost:5000/roles"]?.includes("BASIC") ||
            user["http://locahost:5000/roles"]?.includes("VENTURE")
        : true
    );
    setRole(process.env.REACT_APP_ROLE);
    urlMeeting && setChosse(2);
    urlMeeting && setIframe(urlMeeting);
    console.log("SharedArrayBuffer: ", typeof SharedArrayBuffer === "function");

    return () => {
      newSocket.disconnect();
    };
  }, []);

  const emitSelectProducts = (newProductList) => {
    if (socket) {
      socket.emit("selectproducts", {
        mainproduct: newProductList[0],
        products: newProductList,
      });
      console.log("Update Products: ", socket);
    }
  };

  const emitUpdateProduct = () => {};

  var passWord = "";
  var registrantToken = "";

  const checkVariables = () => {
    let sub = sessionStorage.getItem("sb");
    
    if(globalIdUser === sub){
      setEmail(inputEmail);
      setUserName(inputUserName);
      setViewHostOptions(true);
    }else{
      setInputEmail("idk");
      setEmail(inputEmail);
      setUserName(inputUserName);
    }
   /*  if (inputEmail != email) {
      setRole(0);
      setEmail(inputEmail);
      setUserName(inputUserName);
    } else {
      setViewHostOptions(true);
    } */
  };

  /* function getSignature(e) {
    if (inputEmail != email) {
      setRole(0);
      setEmail(inputEmail);
      setUserName(inputUserName);
    } else {
      setViewHostOptions(true);
    }
    e.preventDefault();

    fetch(process.env.REACT_APP_SIGNATURE, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: inputEmail == email ? role : 0,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        startMeeting(response.signature);
      })
      .catch((error) => {
        console.error(error);
      });
  } */

  const openCheckOut = () => {
    setShowCheckOut(true);
    //setshowIframeBBR(true)
  };

  const closeCheckOut = () => {
     setShowCheckOut(false);
    //setshowIframeBBR(false)
  };

  const loadIframe = () => {
    setActive(true);
  };

  const chooseStore = () => {
    setActive(true);
    setChosse(2);
  };

  const chooseCatalog = () => {
    setActive(true);
    setChosse(2);
  };

  /* function startMeeting(signature) {
    console.log("SIGNATURE: ", signature);
    document.getElementById("zmmtg-root").style.display = "block";

    client.init({
      debug: true,
      zoomAppRoot: meetingSDKElement,
      language: "en-US",
      customize: {
        video: {
          popper: {
            disableDraggable: true,
          },
          isResizable: false,
          viewSizes: {
            default: {
              width: isMobile ? 400 : 875,
              height: 200,
            },
            ribbon: {
              width: 300,
              height: 500,
            },
          },
        },
        meetingInfo: [
          "topic",
          "host",
          "mn",
          "pwd",
          "telPwd",
          "invite",
          "participant",
          "dc",
          "enctype",
        ],
        toolbar: {
          buttons: [
            {
              text: "Custom Button",
              className: "CustomButton",
              onClick: () => {
                console.log("custom button");
              },
            },
          ],
        },
        participants: { disableDraggable: false },
      },
    });
    client.join({
      disableCORP: !window.crossOriginIsolated,
      helper: "./helper.html",
      sdkKey: process.env.REACT_APP_SDK_KEY,
      signature: signature,
      meetingNumber: meetingNumber,
      password: passWord,
      userName: inputUserName,
      userEmail: inputEmail,
      tk: registrantToken,
      success: (success) => {
        console.log(success);
      },
      error: (error) => {
        console.log("error");
        console.log(error);
      },
    });

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      success: (success) => {
        console.log(success);

        ZoomMtg.join({
          disableCORP: !window.crossOriginIsolated,
          helper: "./helper.html",
          signature: signature,
          sdkKey: process.env.REACT_APP_SDK_KEY,
          meetingNumber: meetingNumber,
          passWord: passWord,
          userName: inputUserName,
          userEmail: inputEmail,
          tk: registrantToken,
          success: (success) => {
            console.log(success);
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
    client.on("connection-change", (e) => {
      if (e.state == "Connected") {
        return new Promise((resolve, reject) => {
          let ribbon = document.querySelector('button[title="More"]');

          if (ribbon) {
            ribbon.click();
            console.log("-- switching to speak view --");

            return resolve(true);
          }

          return reject(true);
        })
          .then((r) => {
            let resolve = document.getElementById(
              "menu-list-icon-more"
            ).firstChild;
            if (resolve) {
              resolve.click();
            }
          })
          .catch((error) => {
            console.log("--- error when switching to speak view --> ", error);
          });
      }
    });
  } */

  const updateCart = (item) => {
    setCart((cart) => [...cart, item]);
  };

  const handleChangeZoomRP = (event) => {
    let event_ = event.target.checked;
    console.log("Status Enroll Now: ", event_);
    setViewZoomRP(event_);
    // if(event_){
    //   let url_zoom_rp = "http://localhost:5000/presentations";
    //   window.open(url_zoom_rp, '_blank');
    // }
  };

  const handleOpenShared = () => {
    setOpenShareModal(!openShareModal);
  };

  const handleChangeCreateBundle = (event) => {
    let event_ = event.target.checked;
    console.log("Status Create a Bundle: ", event_);
    setViewCreateBundle(event_);
  };

  const handleChangeEnrollNow = (event) => {
    let event_ = event.target.checked;
    console.log("Status Enroll Now: ", event_);
    setViewEnrollNow(event_);
  };

  useEffect(() => {
    setTotalCart(0);
    cart?.map((elem) => setTotalCart((totalCart) => totalCart + elem.price));
    localStorage.setItem(`cart_${meetingNumber}`, JSON.stringify(cart));
  }, [cart]);

    const storeDiv = useRef(null);
    const scriptRef = useRef(null);
  
    window.localStorage.setItem("show_ecwid_logs","true")
    window.ecwid_script_defer = true;
    window.ecwid_dynamic_widgets = true;
    window.ec = window.ec || Object();
    window.ec.storefront = window.ec.storefront || Object();
    window.ec.enable_catalog_on_one_page = true;
    window._xnext_initialization_scripts = [{
      widgetType: 'ProductBrowser',
      id: 'my-store-#####',
      arg: ["id=productBrowser", "views=grid(20,3)"]  },
      {
        widgetType: 'CategoriesV2',
        id: 'my-categories-#####',
        arg: ["id=categoriesV2"]
      }
      ,
      {
        widgetType: 'SearchWidget',
        id: 'my-search-####',
        arg: ["id=searchWidget"]
      }
    ];

    
  
    
    
/*     
    useEffect(() => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = "https://app.workinglive.biz/script.js?52424037&data_platform=code&data_date=2023-08-15";
      script.async="false";
      script.onload = "()=>{Ecwid.init()}"
      document.body.appendChild(script);
       
    }); */
    /* function addProductBR () {
      Ecwid.OnAPILoaded.add(function() {
        console.log("Ecwid storefront JS API has loaded");
    });

    } */
  
  return (
    <>
      <NotificationUpdate
        message="The host has updated the meeting products"
        visible={showNotification}
      />
       <TopHeader user_name={fullName}></TopHeader>
    
      {showCheckOut && (
        <Checkout
          products={cart}
          onClick={closeCheckOut}
          amount={totalCart}
          totalItems={cart.length}
          affiliateId={affiliateID}
          clearCart={clearCart}
        ></Checkout>
      )}
      {/* esto agregue yo BBRM */}
       {/* <div
        data-layout="BIG_ICON_TITLE_SUBTOTAL"
        data-fixed-shape="PILL"
        data-fixed="TRUE"
        data-fixed-position="BOTTOM_LEFT"
        data-show-buy-animation="TRUE"
        data-responsive="FALSE"
        className="ec-cart-widget"
        style={{
          backgroundColor: "#fff",
          zIndex: "1000000",
          position: "fixed",
          bottom: "40px",
          left: "40px",
          transition: "all 300ms ease 0ms",
        }}
      ></div> 
      <Helmet>
        <script type="text/javascript">Ecwid.init();</script>
      </Helmet> */}
      {/* Aqui termina lo que agregue */}
      <div className="App check-width-mobile-size">
        <main className="main-container-meeting">
          {showSingleSub && (
            <div className="row container-entire-meeting ">
              <div className={"first-column"+(getBasicRole?" basic":" premium")}>
                {renderIframe && (
                  <div
                    className={"iframe-meeting-container"+(getBasicRole?" basic":" premium")}
                  >
                    <ZoomEmbedded userName={inputUserName} email={email} inputEmail={inputEmail} idMeeting ={meetingNumber} token={tokenVerify}/>
                    
                  </div>
                )}
                {/* {!getBasicRole &&    
                  <>
                  {user?.sub == globalIdUser &&(
                    <Box
                      className="host-controls-content"
                      display="flex"
                      justifyContent="space-between"
                      marginLeft="5px"
                    >
                      <Typography variant="h6" fontWeight="bold">
                        Host Shopping Controls
                      </Typography>
                      <Box style={{ display: "flex", gap: "10px" }}>
                        <Button
                          className="start-meeting-tab"
                          onClick={handleOpenShared}
                        >
                          Share This Meeting
                        </Button>
                        <Button
                          className="invite-meeting-tab"
                          onClick={() => {
                            sendProductsForUpdate();
                          }}
                        >
                          Update Products
                        </Button>
                      </Box>
                    </Box>
                    )
                  }
                  {user?.sub == globalIdUser && (
                    
                    <Box
                      className="content-check-uncheck"
                      display="flex"
                      justifyContent="space-between"
                      flexDirection="column"
                      marginLeft="5px"
                      marginBottom="5px"
                    >
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        style={{ color: "#8ac0d1" }}
                      >
                        Check/Uncheck to display
                      </Typography>
                      <Box className="container-host-controls">
                        {totalProducts?.map((item) => {
                          return (
                            <Box className="item-host-controls">
                              <Checkbox
                                id={item.id}
                                value={item.id}
                                defaultChecked={
                                  productsIDs.includes(item.id) ? true : false
                                }
                                onChange={(e) => {
                                  updateProductatatus(item.id, e.target.checked);
                                }}
                              />
                              <img width="70px" src={item.smallThumbnailUrl} />
                              <Box
                                className="item-host-controls-details"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  variant="p"
                                  fontWeight="500"
                                  fontSize=".8rem"
                                >
                                  {item.name}
                                </Typography>
                                <Typography
                                  variant="p"
                                  fontWeight="bold"
                                  fontSize=".8rem"
                                >
                                  {item.defaultDisplayedPriceFormatted}
                                </Typography>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  )}
                  </>  
                } */}
                
              </div>
              {/* {!getBasicRole &&    
              !isMobile && (
                <div className="second-column">
                  {choose == 1 && active && (
                    <>
                      {successOrder ? (
                        <div
                          styles={{
                            border: 0,
                            boxShadow: "rgb(0 0 0 / 24%) 0px 3px 8px",
                          }}
                        >
                          <h2>Success Order</h2>
                        </div>
                      ) : (
                        <Iframe
                          url="https://stagingwave4.com/"
                          width="100%"
                          height="110%"
                          id=""
                          crossorigin="anonymous"
                          allow="shared-workers"
                          loading="eager"
                          className=""
                          display="block"
                          position="relative"
                          styles={{
                            border: 0,
                            boxShadow: "rgb(0 0 0 / 24%) 0px 3px 8px",
                          }}
                        />
                      )}
                      <FloatCloseBtn onClick={closeCheckOut}></FloatCloseBtn>
                    </>
                  )}
                  {email == inputEmail && (
                    <div className="products-content">
                      {showProducts?.map((item) => {
                        return (
                          <div className="card-product">
                            <div className="card-product-image">
                              <img
                                
                                alt=""
                                className="width-products"
                                src={item.thumbnailUrl}
                              />
                            </div>
                            <div>
                              <Typography
                                variant="h6"
                                sx={{
                                  padding: "5px 3px",
                                  display: "-webkit-box",
                                  overflow: "hidden",
                                  WebkitBoxOrient: "vertical",
                                  pl: 5,
                                  WebkitLineClamp: 1,
                                  paddingLeft: "0px",
                                }}
                              >
                                {item.name}
                              </Typography>
                              <Typography
                                variant="h6"
                                textAlign="center"
                                fontWeight="bold"
                                className="card-price"
                              >
                                ${item.price}
                              </Typography>
                              <div className="buttons-container">
                                <button
                                  className="add-bag"
                                  onClick={() => {
                                    updateCart(item);
                                    setitemId([...itemId, item.id]);
                                  }}
                                >
                                  Add to Bag
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {email == inputEmail && (
                    <div
                      className="float"
                      onClick={() => {
                        openCheckOut();
                        //emitSelectProducts();
                      }}
                    >
                      <img
                        
                        alt=""
                        width="30px"
                        className="my-float"
                        src="https://i.imgur.com/TXaAcqV.png"
                      />
                      <div className="content-bag">
                        <p className="bag-empty">
                          {cart.length == 0
                            ? "Bag is empty"
                            : `${cart.length} Items`}
                        </p>
                        <p className="total-color">${totalCart.toFixed(2)}</p>
                      </div>
                    </div>
                  )}
                </div>
              )
              } */}
            </div>
          )}
          {!showSingleSub && (
            <div className="row container-entire-meeting">
              <div className="first-column">
                <div id="meetingSDKElement"></div>
                <div id="meetingSDKChatElement"></div>
                <ShareMeeting />
                {/* <ZoomReadyPresentation meetingId={meetingNumber} /> */}
              </div>
            </div>
          )}
          {/* {isMobile && (
            <div className="second-column mobile-version">
              <div style={{ display: viewEnrollNow ? "block" : "none" }}>
                <h3 style={{ fontWeight: "bold" }}>Enroll Now</h3>
                <p>Details Enroll Now</p>
                <button className="btnChoose-black btn-personalized">
                  Action
                </button>
                <hr></hr>
              </div>
              <div className="products-content">
                {showProducts?.map((item) => {
                  return (
                    <div className="card-product">
                      <div className="card-product-image">
                        <img
                        
                          className="width-products"
                          src={item.thumbnailUrl}
                        />
                      </div>
                      <div>
                        <Typography
                          variant="h6"
                          sx={{
                            padding: "5px 3px",
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            pl: 5,
                            WebkitLineClamp: 1,
                            paddingLeft: "0px",
                          }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          variant="h6"
                          textAlign="center"
                          fontWeight="bold"
                          className="card-price"
                        >
                          ${item.price}
                        </Typography>
                        <div className="buttons-container">
                          <button
                            className="add-bag"
                            onClick={() => {
                              updateCart(item);
                              setitemId([...itemId, item.id]);
                            }}
                          >
                            Add to Bag
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                className="float"
                onClick={() => {
                  //emitSelectProducts();
                  openCheckOut();
                }}
              >
                <img
                
                  width="35px"
                  className="my-float"
                  src="https://i.imgur.com/TXaAcqV.png"
                />
                <div className="count-products">{cart.length}</div>
              </div>
            </div>
          )} */}
          <div className="App">
            <Modal
              isOpen={openModal}
              overlayClassName={{
                base: "overlay-base",
                afterOpen: "overlay-after-change-bg",
                beforeClose: "overlay-before",
              }}
              className={{
                base: "content-base",
                afterOpen: "content-after-max-second",
                beforeClose: "content-before",
              }}
              closeTimeoutMS={500}
            >
              <div style={{ width: "80%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    padding: "15px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                    <img width="250px" src={logob}/>
                 </div>
                <div>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    style={{ textAlign: "center", padding: "20px" }}
                  >
                    ENTER YOUR CREDENTIALS
                  </Typography>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    padding="0px 14px"
                  >
                    If you enter as a guest you can add the Username with which
                    you want the other people in the meeting to see you.
                  </Typography>
                </div>
                {!isVerifiying && (
                  <div className="content-form">
                    <div style={{ width: "80%" }}>
                      <div>
                        <Typography>Name</Typography>
                      </div>
                      <div>
                        <input
                          value={inputUserName || ""}
                          onChange={(e) => setInputUserName(e.target.value)}
                          className="input-credentials"
                        />
                      </div>
                    </div>
                    <div style={{ width: "80%" }}>
                      <div>
                        <Typography>Email</Typography>
                      </div>
                      <div>
                        <input
                          value={inputEmail || ""}
                          onChange={(e) => setInputEmail(e.target.value)}
                          className="input-credentials"
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        className="btn-personalized"
                        onClick={(e) => {
                          checkVariables();
                          saveCredentials();
                          //getSignature(e);
                          setRenderIframe(true);
                          setOpenModal(false);
                          urlMeeting ? chooseStore() : chooseCatalog();
                        }}
                      >
                        Join Meeting
                      </button>
                    </div>
                  </div>
                )}
                {isVerifiying && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    width="100%"
                    flexDirection="column"
                  >
                    <CircularProgress
                      style={{
                        color: "#8B77B6",
                        width: "70px",
                        height: "70px",
                      }}
                    />
                    <Typography variant="h6" fontWeight="bold">
                      Loading...
                    </Typography>
                  </Box>
                )}
              </div>
            </Modal>
          </div>
        </main>
        <SharedInvite
          meetingID={meetingNumber}
          open={openShareModal}
          onClick={handleOpenShared}
        />
      </div>
    </>
  );
}

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

export default WKMeeting;