export const updateZoomConfig = async (token, payload) => {
  const options = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      comp: process.env.REACT_APP_COMP,
    },
    body: JSON.stringify(payload),
  };

  const reqUserSettings = await fetch(
    `${process.env.REACT_APP_MEETINGS}/userssettingszoom`,
    options
  );

  const resUserSettings = await reqUserSettings.json();

  if (!reqUserSettings.ok) {
    return { error: "Error Getting User Settings" };
  }

  if (reqUserSettings.ok) {
    return { data: resUserSettings };
  }
};

export const getUserSettings = async (token) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      comp: process.env.REACT_APP_COMP,
    },
  };

  const reqUserSettings = await fetch(
    `${process.env.REACT_APP_MEETINGS}/userssettingszoom`,
    options
  );

  const resUserSettings = await reqUserSettings.json();

  if (!reqUserSettings.ok) {
    return { error: "Error Getting User Settings" };
  }

  if (reqUserSettings.ok) {
    return { data: resUserSettings };
  }
};
