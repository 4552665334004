import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import YoutubeSearchedForIcon from "@mui/icons-material/YoutubeSearchedFor";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";

import PropTypes from "prop-types";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import CircularProgress from "@mui/material/CircularProgress";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import "../../App.css";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import Layout from "../global/Layout";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import { useAuth0 } from "@auth0/auth0-react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Carrusel from "../../components/Carrusel";
import "./index.scss";
import { CardMeeting } from "../../components/CardMeeting/CardMeeting";
import { CardStats } from "../../components/CardStats/CardStats";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { getUserSettings } from "../../services/zoom/zoomconfig";
import ValidZoomAlert from "./validZoomAlert";
import UpcomingTab from "./dashboardPanel/upcomingTab";
import PreviousTab from "./dashboardPanel/previousTab";


// Utils
const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "#784af4",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#000 !important",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#333333 !important",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "#DEDEDE",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#333333",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#333333 !important",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon fontSize="large" />,
    2: <DevicesOtherIcon fontSize="large" />,
    3: <ReduceCapacityIcon fontSize="large" />,
    4: <YoutubeSearchedForIcon fontSize="large" />,
    5: <InsertInvitationIcon fontSize="large" />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = [
  "MEETING SET UP",
  "CHOOSE PRODUCT VIEW",
  "ADD CO-HOSTS",
  "REVIEW CRITERIA",
  "CREATE INVITE LINK",
];

const tabs = [
  {label:"Upcoming Meetings", tabValue:"1", Content:UpcomingTab, available:true},
  {label:"Previous Meetings", tabValue:"2", Content:PreviousTab, available:true}
]

const basicSteps = ["MEETING SET UP", "REVIEW CRITERIA", "CREATE INVITE LINK"];

Modal.setAppElement("#root");

const Dashboard = () => {
  // auth
  const { user, getAccessTokenSilently, isLoading } = useAuth0();

  const [topic, setInputTopic] = useState(null);
  const [inputDesc, setInputDesc] = useState("");
  const [inputDate, setInputDate] = useState(new Date());
  const [inputTime, setInputTime] = useState(new Date());
   const [teamSelected, setTeamSelected] = useState([]);
  const [loadingInvitation, setLoadingInvitation] = useState(false);
  const [allRecordings, setAllRecordings] = useState([]);
  const [idMeeting, setIDMeeting] = useState(null);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setURL] = useState(null);
  const [items, setitems] = useState([]);
  const [productList, setProductList] = useState([]);
  const [showDate, setShowDate] = useState(new Date());
  const [showTime, setShowTime] = useState(new Date());
  const [createdMeeting, setCreateMeeting] = useState(0);
  const [nextMeeting, setNextMeeting] = useState(null);
  const [dateRange, setDateRange] = useState([
    dayjs().startOf("month").format("YYYY-MM-DD"),
    dayjs().endOf("month").format("YYYY-MM-DD"),
  ]);
  const [invite, setInvite] = useState(false);
  const [emailInvitation, setEmailInvitation] = useState("");
  const [nameInvitation, setNameInvitation] = useState("");
  const [referralInvitation, setReferralINvitation] = useState("");
  const [invitationSended, setInvitationSended] = useState(false);
  const [loadingRecordings, setLoadingRecordings] = useState(false);
  const [validateUser, setValidateUser] = useState(true);
  const [validateUserStatus, setValidateUserStatus] = useState(false);
  const [loadingMeetingsByUser, setLoadingMeetingsByUser] = useState(false);
  const [openShareInvite, setopenShareInvite] = useState(false)
  
  /* const team = [
    {
      id: 1,
      name: "Nancy Baker",
      smallThumbnailUrl:
        "https://img.freepik.com/free-photo/portrait-happy-young-woman-looking-camera_23-2147892777.jpg?w=2000",
    },
    {
      id: 2,
      name: "Tom Reeve",
      smallThumbnailUrl:
        "https://media.istockphoto.com/id/1300512215/photo/headshot-portrait-of-smiling-ethnic-businessman-in-office.jpg?b=1&s=170667a&w=0&k=20&c=TXCiY7rYEvIBd6ibj2bE-VbJu0rRGy3MlHwxt2LHt9w=",
    },
    {
      id: 3,
      name: "Gloria McKenny",
      smallThumbnailUrl:
        "https://www.upthereeverywhere.com/hubfs/2020_People/LizR_portrait-square%20cr.jpeg",
    },
  ]; */

  const [meetings, setMeetings] = useState([]);

  const zoomDomain = "https://meetingssellwithzoom-develop.herokuapp.com";
  const [valueTab, setValueTab] = useState("1");

/* 
  useEffect(() => {
    if (isMacOs) {
      alert("es apple device")
    }
    
    if (isWindows) {
      alert("es android device")
    }else{

    }
  
  }, []) */
  
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const updateProductList = (id) => {
    if (!productList.includes(id)) {
      setProductList((productList) => [...productList, id]);
    } else {
      setProductList([]);
      productList.map((productID) => {
        if (productID != id) {
          setProductList((productList) => [...productList, productID]);
        }
      });
    }
  };

  useEffect(() => {
    document.documentElement.style.overflow = "auto";
    document.body.style.overflow = "auto";

    const getAllRecordings = async () => {
      setLoadingRecordings(true);
      const start = dayjs()
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      const end = dayjs()
        .endOf("month")
        .format("YYYY-MM-DD");
      
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
          comp: "cgcworkinglive",
        },
      };
      await fetch(
        `https://meetingssellwithzoom-develop.herokuapp.com/recordingsbyuser?from_=${start}&to=${end}`,
        options
      )
        .then((res) => res.json())
        .then((res) => {
          setAllRecordings(res.meetings);
          setLoadingRecordings(false);
        })
        .catch((err) => {
          console.log(err);
          setLoadingRecordings(false);
        });
    };

    const getAllProducts = async () => {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer public_z7gaHztX8yD8gwEg8RH2MEzAT65xpFYC",
        },
      };

      await fetch(
        `https://productssellwithzoom.herokuapp.com/products?storeId=52433035&page_size=30&page_number=1`,
        options
      )
        .then((response) => response.json())
        .then((result) => {
          setitems(result?.products);
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    getAllRecordings();
    //getAllProducts();

    const getUserStatus = async () => {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
          user_id:user?.sub,
          comp: "cgcworkinglive",
        },
      };
      await fetch(
        "https://companduser-develop.herokuapp.com/user",
        options
      )
        .then((res) => res.json())
        .then((res) => {
          setValidateUserStatus(res?.status?.toLowerCase() != "active");
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    user?.sub && getUserStatus();
  }, [user?.sub]);

  const sendInvitation = async () => {
    setLoadingInvitation(true);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
        comp: "cgcworkinglive",
      },
      body: JSON.stringify({
        name: nameInvitation,
        email: emailInvitation,
        referarstring: referralInvitation,
      }),
    };

    fetch("https://companduser-develop.herokuapp.com/addteam", options)
      .then((res) => res.json())
      .then((res) => {
        setLoadingInvitation(false);
        setInvitationSended(true);
      })
      .catch((err) => {
        console.log(err);
        setLoadingInvitation(false);
      });
  };

  const updateTeamSelected = (id) => {
    if (!teamSelected.includes(id)) {
      setTeamSelected((teamSelected) => [...teamSelected, id]);
    } else {
      setTeamSelected([]);
      teamSelected.map((productID) => {
        if (productID != id) {
          setTeamSelected((teamSelected) => [...teamSelected, productID]);
        }
      });
    }
  };

  useEffect(() => {
    const dateShow = new Date(inputDate).toString().split(" ").slice(0, 4);
    const concatDate =
      dateShow.slice(0, 3).join(" ") + ", " + dateShow.slice(3, 4).join(" ");
    setShowDate(concatDate);
    const dateStart = new Date(inputDate).toLocaleTimeString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    setShowTime(
      [dateStart.split(":")[0], dateStart.split(":")[1]].join(":") +
        " " +
        dateStart.split(" ")[1] +
        " " +
        Intl.DateTimeFormat().resolvedOptions().timeZone?.split("_").join(" ")
    );
  }, [inputTime, inputDate]);

  useEffect(() => {
    if (!isLoading) {
      setValidateUser(true);
      if (!user.email_verified) {
        window.location.replace("/verify");
      } else if (user["http://locahost:5000/roles"]?.length == 0) {
        window.location.replace("/payment");
      } else {
        setValidateUser(false);
      }
    }
  }, [isLoading]);

  useEffect(() => {
    setLoadingMeetingsByUser(true);
    const loadMeetings = async () => {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
          comp: "cgcworkinglive",
        },
      };

      await fetch(
        `https://backzoom-develop.herokuapp.com/meetingDBByUser?date_from=${dateRange[0]}&date_to=${dateRange[1]}&id_user=${user?.sub}&page_size=30&page_number=1`,
        options
      )
        .then((response) => response.json())
        .then((result) => {
          setMeetings(result?.meetings);
          setNextMeeting(result?.meetings ? {...result?.meetings[0], meeting_id:result?.meetings[0]?.id} : null);
          setLoadingMeetingsByUser(false);
        })
        .catch((error) => {
          console.log("error", error);
          setLoadingMeetingsByUser(false);
        });
    };

   /*  const loadPreviousMeetings = async () => {
      setLoadingPreviousMeetings(true);
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
          comp: "cgcworkinglive",
        },
      };
      await fetch(
        `https://backzoom-develop.herokuapp.com/meetingDBByUser?date_from=${dateRange[0]}&date_to=${dateRange[1]}&id_user=${user?.sub}&status=ended&page_size=30&page_number=1`,
        options
      )
        .then((response) => response.json())
        .then((result) => {
          setPreviousMeetings(result?.meetings);
          setLoadingPreviousMeetings(false);
        })
        .catch((error) => {
          console.log("Error loading previous Meetings", error);
          setLoadingPreviousMeetings(false);
        });
    }; */

    user?.sub && loadMeetings();
    //user?.sub && loadPreviousMeetings();
  }, [createdMeeting]);

  const openMeeting = () => {
    let url = document.getElementById("txtLinkMeeting").value;
    window.open(
      `${window.location.origin}/${idMeeting}${
        url ? `?loadIframe=` + url : ""
      }`,
      "_blank"
    );
  };

  const openShareModal = () => {
    setopenShareInvite(true)
  }

  const copyInvitation = (id) => {
    navigator.clipboard
      .writeText(
        `${window.location.origin}/${idMeeting}${
          url ? `?loadIframe=` + url : ""
        }`
      )
      .then(() => {
        setCopied(true);
      });
  };

  /* const handleInvite = () => {
    setInvite(true);
  }; */

  const generateMeeting = async () => {
    setLoading(true);
    const myToken = await getAccessTokenSilently();

    const gettingUserSettings = await getUserSettings(myToken);
    let user_settings = {};
    if (gettingUserSettings.data){
      user_settings= {
        auto_recording: gettingUserSettings?.data?.recording?.auto_recording
      };
    }
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${myToken}`,
        comp: process.env.REACT_APP_COMP,
      },
      body: JSON.stringify({
        owner: user.sub,
        start_time: `${inputDate}T${inputTime}Z`,
        topic: topic,
        description: inputDesc,
        settings: user_settings,
        type: 2,
        product_ids: {
          mainproduct: 0,
          products: productList,
        },
        url_store: "",
      }),
    };

    await fetch(`${zoomDomain}/meeting`, options)
      .then((response) => response.json())
      .then((result) => {
        setIDMeeting(result.meeting_id);
        setLoading(false);
        setCreateMeeting(createdMeeting + 1);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const filterMeetingsByUser = async () => {
    setLoadingMeetingsByUser(true);
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
        comp: "cgcworkinglive",
      },
    };

    fetch(
      `https://backzoom-develop.herokuapp.com/meetingDBByUser?date_from=${
        new Date(dateRange[0]?.$d).toISOString().split("T")[0]
      }&date_to=${
        new Date(dateRange[1]?.$d).toISOString().split("T")[0]
      }&id_user=${user?.sub}&page_size=30&page_number=1`,
      options
    )
      .then((response) => response.json())
      .then((result) => {
        setMeetings(result?.meetings);
        setNextMeeting(result?.meetings ? result?.meetings[0] : null);
        setLoadingMeetingsByUser(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoadingMeetingsByUser(false);
      });
  };

  

  /* PAra copiar a invitacion */
  const [copiedLink, setCopiedLink] = useState(false);

  const facebook = "/assets/images/virtual_skin/fb.svg";
  const telegram = "/assets/images/virtual_skin/tel.svg";
  const msjText = "/assets/images/virtual_skin/msj.svg";
  const tw = "/assets/images/virtual_skin/tw.svg";
  const pint = "/assets/images/virtual_skin/pint.svg";
  const email = "/assets/images/virtual_skin/mail.svg";

  const copyText = (meeting_id) => {
    navigator.clipboard
      .writeText(window.location.origin + "/" + `${meeting_id}`)
      .then(() => {
        setCopiedLink(true);
      })
      .catch((err) => {
        setCopiedLink(false);
        console.log("Something went wrong", err);
      });
  };
  const openNewTab = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      {validateUser && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          width="100vw"
          flexDirection="column"
        >
          <CircularProgress
            style={{ color: "#00000", width: "70px", height: "70px" }}
          />
          <Typography variant="h6" fontWeight="bold">
            Loading...
          </Typography>
        </Box>
      )}

        {/* Modal to share*/}
      <div className="App">
        <Modal
          isOpen={openShareInvite}
          // onRequestClose={() => setConfirmModal(false)}
          overlayClassName={{
            base: "overlay-base first",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-share",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          <Box className="share-meeting-link-container">
            <Typography
              variant="h4"
              fontWeight="bold"
              textAlign="left"
              style={{
                fontFamily: "inherit",
                width: "100%",
                paddingLeft: "30px",
              }}
            >
              Easy Share
            </Typography>
            <Box className="share-meeting-input-link">
              <TextField
                id="personalized-outlined-basic"
                label="Invitation Link"
                variant="outlined"
                value={window.location + idMeeting}
                style={{ width: "70%" }}
              />
              <Button
                className="open-presentation-zrp set-btn-size"
                onClick={() => copyText(idMeeting)}
              >
                {copiedLink ? "Copied link" : "Copy Invite Link"}
              </Button>
            </Box>
            <Box className="share-meeting-link">
              <Box
                className="share-meeting-link-item"
                onClick={() =>
                  openNewTab(
                    `https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/${idMeeting}`
                  )
                }
              >
                <img
                  
                  alt="facebook"
                  src={facebook}
                  width="50px"
                  height="50px"
                  style={{ borderRadius: "50%" }}
                />
                <Typography
                  fontWeight="bold"
                  variant="h6"
                  textAlign="center"
                  style={{ fontFamily: "inherit" }}
                >
                  Facebook
                </Typography>
              </Box>
              <Box
                className="share-meeting-link-item"
                onClick={() => {
                  openNewTab(
                    `https://t.me/share/url?url=${window.location.origin}/${idMeeting}`
                  );
                }}
              >
                <img
                
                  alt="telegram"
                  src={telegram}
                  width="50px"
                  height="50px"
                  style={{ borderRadius: "50%" }}
                />
                <Typography
                  fontWeight="bold"
                  variant="h6"
                  textAlign="center"
                  style={{ fontFamily: "inherit" }}
                >
                  Telegram
                </Typography>
              </Box>
              <Box
                className="share-meeting-link-item"
                onClick={() =>
                  openNewTab(
                    `sms:?&body=${window.location.origin}/${idMeeting}`
                  )
                }
              >
                <img
                
                  alt="msjText"
                  src={msjText}
                  width="50px"
                  height="50px"
                  style={{ borderRadius: "50%" }}
                />
                <Typography
                  fontWeight="bold"
                  variant="h6"
                  textAlign="center"
                  style={{ fontFamily: "inherit" }}
                >
                  Messages
                </Typography>
              </Box>
              <Box
                className="share-meeting-link-item"
                onClick={() =>
                  openNewTab(
                    `https://twitter.com/intent/tweet?url=${window.location.origin}/${idMeeting}`
                  )
                }
              >
                <img
                
                  alt="tw"
                  src={tw}
                  width="50px"
                  height="50px"
                  style={{ borderRadius: "50%" }}
                />
                <Typography
                  fontWeight="bold"
                  variant="h6"
                  textAlign="center"
                  style={{ fontFamily: "inherit" }}
                >
                  Twitter
                </Typography>
              </Box>
              <Box
                className="share-meeting-link-item"
                onClick={() =>
                  openNewTab(
                    `mailto:info@example.com?&subject=&cc=&bcc=&body=${window.location.origin}/${idMeeting}`
                  )
                }
              >
                <img
                
                  alt="email"
                  src={email}
                  width="50px"
                  height="50px"
                  style={{ borderRadius: "50%" }}
                />
                <Typography
                  fontWeight="bold"
                  variant="h6"
                  textAlign="center"
                  style={{ fontFamily: "inherit" }}
                >
                  Email
                </Typography>
              </Box>
            </Box>
          </Box>
          <button
            className="close-modal"
            onClick={() => {
              setopenShareInvite(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button>
        </Modal>
      </div>


      {validateUserStatus && (
        <ValidZoomAlert user={user}/>
      )}

      {!validateUser && (
        <Layout>
          <Box className="dashboard-container">
            {/* HEADER */}
            <Box
              display="flex"
              justifyContent="flex-start"
              flexDirection="column"
              gap="15px"
            >
              {/* Carrusel + header */}
              <Box width="100%" className="content-slider">
                <Carrusel
                  navButtonsProps={{
                    // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                    style: {
                      backgroundColor: "#red",
                      borderRadius: 0,
                    },
                  }}
                  navButtonsWrapperProps={{
                    // Move the buttons to the bottom. Unsetting top here to override default style.
                    style: {
                      bottom: "0",
                      backgroundColor: "#red",
                      top: "unset",
                    },
                  }}
                ></Carrusel>
                <div className="slider-content-sub">
                  <div>
                    <h4 style={{ fontWeight: "bold", textAlign: "start", textTransform:"capitalize"}}>
                      Hi, {user?.nickname}
                    </h4>
                  </div>
                  <div className="sub-text-slider">
                    <span style={{ fontSize: "13px", textAlign: "left" }}>
                      Welcome, start selling with Zoom{" "}
                      {!user["http://locahost:5000/roles"]?.includes("BASIC") &&
                        !user["http://locahost:5000/roles"]?.includes(
                          "VENTURE"
                        ) &&
                        "shopping"}{" "}
                      today!
                    </span>
                  </div>
                </div>
              </Box>

              <Box className="tab-meetings-dashboard">
                <TabContext value={valueTab ? valueTab : "1"}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <TabList
                      onChange={handleChangeTab}
                      aria-label="lab API tabs example"
                      sx={{
                        "& button": {
                          borderTopLeftRadius: 2,
                          borderTopRightRadius: 2,
                        },
                        "& button:hover": { backgroundColor: "#779f6052" },
                        "& button:focus": { backgroundColor: "#779f6052" },
                        "& button.Mui-selected": {
                          backgroundColor: "#779f6052",
                          color: "#000000 !important",
                        },
                        "& .MuiTabs-flexContaine": {
                          height: "50px",
                        },
                        "& .MuiButtonBase-root": {
                          fontSize: "12px !important",
                          width: "200px",
                          height: "50px",
                        },
                      }}
                    >
                      {tabs?.map((value, index)=>{
                        return (
                          <Tab
                            label={value?.label}
                            value={value?.tabValue}
                            style={{
                              width: "200px",
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#06213b",
                              textAlign: "center",
                              paddingTop: "15px",
                              paddingBottom: "15px",
                              paddingLeft: "40px",
                              paddingRight: "40px",
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                              marginLeft: "5px",
                              marginRight: "5px",
                            }}
                            focusVisibleClassName="focus-tab"
                          />
                        )
                      })}
                    </TabList>
                  </Box>
                  {tabs?.map((value, index)=>{
                    return (
                      <value.Content 
                        validateUserStatus={validateUserStatus} 
                        dateRange={dateRange} 
                        setDateRange={setDateRange} 
                        filterMeetingsByUser={filterMeetingsByUser} 
                        meetings={meetings}
                        loadingMeetings={loadingMeetingsByUser}
                        setCreateMeeting={setCreateMeeting}
                        createdMeeting={createdMeeting}/>
                    )
                  })}                  
                </TabContext>
              </Box>
            </Box>
            {/* Sidebar */}
            <Box className="right-side-cards">
              <CardMeeting
                details={nextMeeting}
                isActive={validateUserStatus}
              />
              {!user["http://locahost:5000/roles"]?.includes("BASIC") &&
                !user["http://locahost:5000/roles"]?.includes("VENTURE") && (
                  <CardStats
                    title="Total Meeting Sales"
                    totalValue="$632.00"
                    details="VIEW ALL SALES"
                  />
                )}
              {!user["http://locahost:5000/roles"]?.includes("BASIC") &&
                !user["http://locahost:5000/roles"]?.includes("VENTURE") && (
                  <CardStats
                    title="Product Sold"
                    totalValue="123"
                    details="VIEW PRODUCTS"
                  />
                )}
              {(user["http://locahost:5000/roles"]?.includes("BASIC") ||
                user["http://locahost:5000/roles"]?.includes("VENTURE")) && (
                <Box className="recording-stats-container">
                  <Box>
                    <Typography
                      variant="h4"
                      fontWeight="bold"
                      sx={{ padding: "10px" }}
                    >
                      Recent Recordings
                    </Typography>
                    <Typography variant="h6" color="#B3B3B3">
                      Review all meetings that have been recorded.
                    </Typography>
                  </Box>
                  {loadingRecordings && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100vh"
                      flexDirection="column"
                    >
                      <CircularProgress
                        style={{
                          color: "#00000",
                          width: "70px",
                          height: "70px",
                        }}
                      />
                      <Typography variant="h6" fontWeight="bold">
                        Loading...
                      </Typography>
                    </Box>
                  )}
                  {allRecordings?.length > 0 && !loadingRecordings && (
                    <Box style={{ width: "100%" }}>
                      {allRecordings?.map((itemMeeting) => {
                        return (
                          <Box className="recordings-items">
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <PlayCircleOutlinedIcon
                                fontSize="large"
                                style={{ color: "#428aca", cursor: "pointer" }}
                                onClick={() =>
                                  window.open(itemMeeting.share_url)
                                }
                              />
                              <Typography
                                className="topic-recording-item"
                                variant="h6"
                                onClick={() =>
                                  window.open(itemMeeting.share_url)
                                }
                              >
                                {itemMeeting?.topic}
                              </Typography>
                            </Box>

                            <Typography variant="h6" fontWeight={600}>
                              {new Date(
                                itemMeeting?.start_time
                              ).toLocaleDateString()}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                  {allRecordings?.length == 0 && (
                    <Box style={{ width: "100%" }}>
                      <Typography variant="h6" fontWeight="bold">
                        No recent recordings found
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Layout>
      )}
      

      
    </>
  );
};

export default withAuthenticationRequired(Dashboard);
