import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "react-avatar-edit";
import updatePicture from "../../services/updateUser/updatePicture";
import { useAuth0 } from "@auth0/auth0-react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ProfileImage = (props) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [open, setOpen] = React.useState(false);
  const [imgCrop, setImgCrop] = useState(false);

  const onCrop = (view) => {
    setImgCrop(view);
  };

  const onClose = () => {
    setImgCrop(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = async () => {
    await updateMyPicture(imgCrop);
    setOpen(false);
  };

  const updateMyPicture = async (url) => {
    const myToken = await getAccessTokenSilently();
    const payload = {
      user_metadata: { picture: url },
    };
    await updatePicture(myToken, payload, user.sub);
  };

  return (
    <div>
      {/* <Button
        variant="outlined"
        onClick={handleClickOpen}
        style={{ marginBottom: "10px" }}
      >
        Edit Profile Picture
      </Button> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ fontWeight: "bold" }}
        >
          Update Profile Image
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Avatar width={400} height={300} onClose={onClose} onCrop={onCrop} />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            className="invite-meeting-tab"
            disabled={!imgCrop}
          >
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ProfileImage;
