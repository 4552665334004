const getSuscriptons = async (org, id) => {
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  };

  await fetch(
    `https://payment-service-orkrbzqvda-uc.a.run.app/nmi/subscription/get-subscriptions?org=${org}&user_id=${id}`,
    options
  )
    .then((res) => {
      if (res.ok) return res.json();
      else throw new Error("Error Getting Suscriptions");
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return { error: error };
    });
};

export default getSuscriptons;
