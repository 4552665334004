import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, Input, Typography } from "@mui/material";
import "./index.scss";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { FloatCloseBtn } from "../FloatCloseBtn";
import InlineCartPage from "../CollectJsSection/inlineCartForm";
import { useParams } from "react-router-dom";
import Confetti from "react-confetti";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const Checkout = (props) => {
  const [checkOutEmail, setCheckOutEmail] = useState("");
  const [country, setCountry] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [province, setProvince] = useState("");
  const [stepNumber, setStepNumber] = useState(1);
  const [items, setItems] = useState([]);
  const [orderNumber, setOrderNumber] = useState(77);

  let { meetingNumber } = useParams();

  useEffect(() => {
    const idProducts = props?.products?.map((product) => product.id);
    const count = {};
    idProducts.forEach((number) => {
      count[number] = count[number] ? count[number] + 1 : 1;
    });

    const myProducts = Object.entries(count).map(([productValue, quantity]) => {
      const productId = parseInt(productValue);
      return {
        productId,
        quantity,
      };
    });
    setItems(myProducts);
  }, [props?.products]);

  const handleThankYou = () => {
    setStepNumber(5);
  };

  return (
    <>
      <Box className="checkout-working-live">
        {stepNumber == 5 && (
          <Confetti numberOfPieces={70} tweenDuration={50}></Confetti>
        )}
        {stepNumber == 1 ? (
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ padding: "12px", fontFamily: "Lato" }}
          >
            Your Bag
          </Typography>
        ) : (
          stepNumber != 5 && (
            <Typography variant="h4" fontWeight="bold" sx={{ padding: "12px" }}>
              Checkout Information
            </Typography>
          )
        )}
        {/* First Step */}
        {stepNumber == 1 && (
          <Box>
            <Box className="checkout-working-live-products">
              {props?.products?.map((product, index) => (
                <Box key={index} height="100%">
                  <CloseOutlinedIcon
                    style={{ color: "#000" }}
                    className="delete-product-cross"
                  ></CloseOutlinedIcon>
                  <img src={product.smallThumbnailUrl} width="70px" />
                </Box>
              ))}
            </Box>
            <Box className="checkout-working-live-total">
              <Typography variant="h6" fontWeight="bold">
                {props?.totalItems} items
              </Typography>
              <Typography variant="h6" fontWeight="bold">
                ${props?.amount.toFixed(2)}
              </Typography>
            </Box>
            <Box className="checkout-working-live-email-form">
              <Typography variant="p" fontSize="1rem">
                Enter your email address. This address will be used to send you
                order status updates.
              </Typography>
              <Box className="checkout-working-live-email">
                <input
                  className="checkout-working-live-email-form-input"
                  placeholder="Your email address"
                  value={checkOutEmail}
                  onChange={(e) => setCheckOutEmail(e.target.value)}
                />
              </Box>
              <Box className="checkout-working-live-check">
                <Checkbox style={{ color: "#000", width: "20px" }}></Checkbox>
                <Typography variant="p" fontSize="1rem" marginLeft="5px">
                  Keep me up to date on news and exclusive offers
                </Typography>
              </Box>
              <Box className="checkout-working-live-btn-checkout">
                <button
                  disabled={!checkOutEmail}
                  onClick={() => setStepNumber(2)}
                >
                  Checkout
                </button>
              </Box>
              <Box className="checkout-working-live-btn-checkout">
                <Typography variant="h6" fontSize="1rem">
                  Have a promo coupon? <span>Redeem your coupon</span>
                </Typography>
              </Box>
              <Box className="checkout-working-live-next">
                <Typography variant="h5" fontWeight="600">
                  Next
                </Typography>
              </Box>
              <Box className="checkout-working-live-steps">
                <Box>
                  <Typography variant="h6" fontWeight="500">
                    Delivery options
                  </Typography>
                  <Typography variant="h6" color="#9D9D9D">
                    Select how you’ll be receiving your order.
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" fontWeight="500">
                    Payment information
                  </Typography>
                  <Typography variant="h6" color="#9D9D9D">
                    Choose a payment method and enter your payment details.
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" fontWeight="500">
                    Order confirmation
                  </Typography>
                  <Typography variant="h6" color="#9D9D9D">
                    Place your order and receive a confirmation email.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {stepNumber == 2 && (
          <Box>
            <Box className="checkout-working-live-products">
              {props?.products?.map((product, index) => (
                <Box key={index}>
                  <img src={product.smallThumbnailUrl} width="70px"  />
                </Box>
              ))}
            </Box>
            <Box className="checkout-working-live-total">
              <Typography variant="h5" fontWeight="bold">
                {props?.totalItems} items
              </Typography>
              <Typography variant="h5" fontWeight="bold">
                ${props?.amount.toFixed(2)}
              </Typography>
            </Box>
            <Box className="checkout-working-live-email-form">
              <Typography variant="h6" className="add-borders">
                {checkOutEmail}{" "}
                <a
                  className="add-borders-change"
                  onClick={() => setStepNumber(1)}
                >
                  Change Email
                </a>
              </Typography>
              <Box className="checkout-working-live-check">
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  className="second-step-ship"
                >
                  Shipping & Delivery
                </Typography>
              </Box>
              <Box className="checkout-working-live-btn-checkout">
                <Typography variant="p" fontSize="1rem">
                  All fields are required unless they're explicitly marked as
                  optional.
                </Typography>
              </Box>
              <Box className="checkout-working-live-next">
                <Typography variant="h6" fontWeight="600">
                  Country
                </Typography>
                <select
                  name="country"
                  placeholder="Please select a country"
                  value={country}
                  className="checkout-working-live-email-form-input"
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option value="" style={{ color: "#757575" }}>
                    Please select a country
                  </option>
                  <option value="US">United States</option>
                  <option value="CA">Canada</option>
                </select>
              </Box>
              <Box className="checkout-working-live-next">
                <Typography variant="h6" fontWeight="600">
                  Full name
                </Typography>
                <input
                  className="checkout-working-live-email-form-input"
                  placeholder="Enter your Full Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </Box>
              <Box className="checkout-working-live-next">
                <Typography variant="h6" fontWeight="600">
                  Phone (optional)
                </Typography>
                <input
                  className="checkout-working-live-email-form-input"
                  placeholder="Optional Phone"
                  type="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Box>
              <Box className="checkout-working-live-next">
                <Typography variant="h6" fontWeight="600">
                  Address
                </Typography>
                <Box className="checkout-working-live-next-input-address">
                  <input
                    className="checkout-working-live-email-form-input"
                    placeholder="Street address, apartment, suite, floor, etc"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  <input
                    className="checkout-working-live-email-form-input"
                    placeholder="City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                  <input
                    className="checkout-working-live-email-form-input"
                    placeholder="Postal Code (Optional)"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                  />
                  <select
                    name="country"
                    placeholder="Please select a State"
                    className="checkout-working-live-email-form-input"
                    value={province}
                    onChange={(e) => setProvince(e.target.value)}
                  >
                    <option value="" style={{ color: "#757575" }}>
                      Please select a State
                    </option>
                    <option value="TN">Tennessee</option>
                    <option value="FL">Florida</option>
                    <option value="UT">Utah</option>
                  </select>
                </Box>
              </Box>
              <Box className="checkout-working-live-btn-checkout">
                <button
                  disabled={
                    !province || !city || !address || !fullName || !country
                  }
                  onClick={() => setStepNumber(3)}
                >
                  Continue
                </button>
              </Box>
              <Box className="checkout-working-live-next">
                <Typography variant="h5" fontWeight="600">
                  Next
                </Typography>
              </Box>
              <Box className="checkout-working-live-steps">
                <Box>
                  <Typography variant="h6" fontWeight="500">
                    Payment information
                  </Typography>
                  <Typography variant="h6" color="#9D9D9D">
                    Choose a payment method and enter your payment details.
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" fontWeight="500">
                    Order confirmation
                  </Typography>
                  <Typography variant="h6" color="#9D9D9D">
                    Place your order and receive a confirmation email.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {stepNumber == 3 && (
          <Box>
            <Box className="checkout-working-live-products">
              {props?.products?.map((product, index) => (
                <Box key={index}>
                  <img src={product.smallThumbnailUrl} width="70px" />
                </Box>
              ))}
            </Box>
            <Box className="checkout-working-live-total">
              <Typography variant="h5" fontWeight="bold">
                {props?.totalItems} items
              </Typography>
              <Typography variant="h5" fontWeight="bold">
                ${(props?.amount + 10).toFixed(2)}
              </Typography>
            </Box>
            <Box className="checkout-working-live-email-form">
              <Typography
                variant="p"
                className="add-borders"
                style={{ fontSize: "1.2rem" }}
              >
                {checkOutEmail}{" "}
                <a
                  className="add-borders-change"
                  onClick={() => setStepNumber(1)}
                >
                  Change Email
                </a>
              </Typography>
              <Typography variant="p" style={{ fontSize: "1.2rem" }}>
                {fullName}
              </Typography>
              <Typography variant="p" style={{ fontSize: "1.2rem" }}>
                {address}, {city}, {province},{postalCode}, {country}
              </Typography>
              <Typography variant="p" style={{ fontSize: "1.2rem" }}>
                <a
                  className="add-borders-change"
                  onClick={() => setStepNumber(2)}
                >
                  Change address
                </a>
              </Typography>
              <Box className="checkout-working-live-check">
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  className="second-step-ship"
                >
                  Shipping method
                </Typography>
              </Box>
              <Box className="checkout-working-live-btn-checkout">
                <Box className="checkout-working-live-email-form-input">
                  <FormControl className="checkout-working-live-form-control">
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="UPS Shipping"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="UPS Shipping"
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 28,
                                color: "#000",
                              },
                            }}
                          />
                        }
                        label="UPS Shipping - $10.00"
                        className="shipping-option"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
              <Box className="checkout-working-live-btn-checkout">
                <button onClick={() => setStepNumber(4)}>Continue</button>
              </Box>
              <Box className="checkout-working-live-next">
                <Typography variant="h5" fontWeight="600">
                  Next
                </Typography>
              </Box>
              <Box className="checkout-working-live-steps">
                <Box>
                  <Typography variant="h6" fontWeight="500">
                    Payment information
                  </Typography>
                  <Typography variant="h6" color="#9D9D9D">
                    Choose a payment method and enter your payment details.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {stepNumber == 4 && (
          <Box>
            <Box className="checkout-working-live-products">
              {props?.products?.map((product, index) => (
                <Box key={index}>
                  <img src={product.smallThumbnailUrl} width="70px" />
                </Box>
              ))}
            </Box>
            <Box className="checkout-working-live-total">
              <Typography variant="h5" fontWeight="bold">
                {props?.totalItems} items
              </Typography>
              <Typography variant="h5" fontWeight="bold">
                ${(props?.amount + 10).toFixed(2)}
              </Typography>
            </Box>
            <Box className="checkout-working-live-email-form">
              <Typography
                variant="p"
                className="add-borders"
                style={{ fontSize: "1.2rem" }}
              >
                {checkOutEmail}{" "}
                <a
                  className="add-borders-change"
                  onClick={() => setStepNumber(1)}
                >
                  Change Email
                </a>
              </Typography>
              <Box className="checkout-working-live-email-form add-borders">
                <Typography variant="p" style={{ fontSize: "1.2rem" }}>
                  {fullName}
                </Typography>
                <Typography variant="p" style={{ fontSize: "1.2rem" }}>
                  {address}, {city}, {province},{postalCode}, {country}
                </Typography>
                <Typography variant="p" style={{ fontSize: "1.2rem" }}>
                  <a
                    className="add-borders-change"
                    onClick={() => setStepNumber(2)}
                  >
                    Change address
                  </a>
                </Typography>
              </Box>

              <Typography
                variant="p"
                style={{ fontSize: "1.2rem" }}
                className="add-borders"
              >
                UPS Shipping, $10.00{" "}
                <a
                  className="add-borders-change"
                  onClick={() => setStepNumber(3)}
                >
                  Change method
                </a>
              </Typography>
              <Box className="checkout-working-live-check">
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  className="second-step-ship"
                >
                  Payment Information
                </Typography>
              </Box>
              <Box className="checkout-working-live-btn-checkout">
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  className="second-step-ship"
                >
                  Choose one way to pay for your order
                </Typography>
                <Box className="checkout-working-live-email-form-input">
                  <FormControl className="checkout-working-live-form-control">
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="Credit Card"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Credit Card"
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 28,
                                color: "#000",
                              },
                            }}
                          />
                        }
                        label="Credit Card"
                        className="shipping-option"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
              <InlineCartPage
                items={items}
                meetingNumber={meetingNumber}
                affiliateId={props.affiliateId}
                amount={props.amount}
                email={checkOutEmail}
                name={fullName}
                street={address}
                city={city}
                countryCode={country}
                postalCode={postalCode}
                stateOrProvinceCode={province}
                phone={phone}
                onClick={handleThankYou}
                clearCart={props.clearCart}
              ></InlineCartPage>
              {/* <PaymentForm></PaymentForm> */}
              {/* <Box className="checkout-working-live-btn-checkout">
                <button onClick={() => setStepNumber(4)}>Send Pay</button>
              </Box> */}
            </Box>
          </Box>
        )}

        {stepNumber == 5 && (
          <Box className="thank-you-container">
            <Box>
              <Typography
                variant="h5"
                textAlign="center"
                fontWeight="bold"
                padding="15px 5px 5px 5px"
              >
                Thank you for your order! 🎉
              </Typography>
              <Typography
                variant="h6"
                textAlign="center"
                style={{ padding: "10px" }}
              >
                Order confirmation and updates will be sent to {checkOutEmail}
              </Typography>
            </Box>

            <Box className="thank-you-container-text">
              <Typography variant="h5" fontWeight="bold">
                Order #{orderNumber}
              </Typography>
              <Typography variant="h6">{new Date().toString()}</Typography>
            </Box>
            <Box className="thank-you-container-text">
              <Typography variant="h6" fontWeight="bold">
                Payment Status: PAID
              </Typography>
              <Typography variant="h6">Credit Card</Typography>
              <Typography variant="h6">
                Total ${props.amount.toFixed(2)}
              </Typography>
            </Box>
            <Box className="thank-you-container-text">
              <Typography variant="h5" fontWeight="bold">
                Your Order
              </Typography>
              <Box className="checkout-working-live-products">
                {props?.products?.map((product, index) => (
                  <Box key={index}>
                    <img src={product.smallThumbnailUrl} width="70px" />
                  </Box>
                ))}
              </Box>
            </Box>
            <Box className="invert-continue-shopping">
              <button
                onClick={() => {
                  props.onClick();
                  props.clearCart();
                }}
              >
                Continue Shopping
              </button>
            </Box>
          </Box>
        )}
      </Box>
      <FloatCloseBtn
        onClick={() => {
          props.onClick();
        }}
      ></FloatCloseBtn>
    </>
  );
};
export default Checkout;
