export const validateErrors = ({ newObject, seterrorObject, checkoutSchema }) => {
    let valid = true;
    seterrorObject(checkoutSchema?.reduce((prv, nw) => {
        if (nw?.regExp) {
            let regex = new RegExp(nw?.regExp)
            if(!regex.test(newObject[nw?.name])){
                valid = false;
            }
            return { ...prv, [nw?.name]: regex.test(newObject[nw?.name]) ? undefined : nw?.error || true }
        } else {
            if(emptyValue(newObject[nw?.name], nw?.error)!=undefined){
                valid = false;
            }
            return { ...prv, [nw?.name]: emptyValue(newObject[nw?.name], nw?.error) }
        }
    }, {}));
    return valid;
}

export const isValidObject = ({errorObject, checkoutSchema})=> {
    let valid = false;
    checkoutSchema?.map((val, inde)=>{
        if(errorObject[val]){
        }else{
            valid=false;
        }
    })
    return valid;
}

export const emptyValue = (value, defaultMsg) => {
    if (value && value?.trim() != "" && value?.trim() != ".") {
        return undefined;
    } else {
        if (defaultMsg) {
            return defaultMsg;
        } else {
            return true;
        }
    }
}

export const getRequiredMessage = (value) => {
    if (value === true || value == "true") {
        return "Required value";
    } else {
        return value
    }
}