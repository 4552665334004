export const replaceObjectById = (array, newObj) => {
  const newArray = array.map((obj) => {
    if (obj.ID === newObj.ID) {
      return newObj;
    }
    return obj;
  });

  return newArray;
};
