import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Box } from "@mui/material";
import "./index.scss";

const NotificationUpdate = ({ message, visible }) => {
  return (
    <Box className={`notification-update ${visible ? "visible" : ""}`}>
      <Alert severity="info">
        <AlertTitle style={{ fontWeight: "bold" }}>Info</AlertTitle>
        This is an info alert — <strong>{message}!</strong>
      </Alert>
    </Box>
  );
};

export default NotificationUpdate;
