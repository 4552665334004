import { useState, useEffect } from "react";
import { ColorModeContext, useMode } from "../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Topbar from "./Topbar";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { useAuth0 } from "@auth0/auth0-react";
import { getUserStatus } from "../../services/middleware/verify";
import { setGlobalState, useGlobalState } from "../../hooks/state";
import { AlertInfo } from "../../components/AlertInfo";

const Layout = ({ children, title }) => {
  const [userStatus] = useGlobalState("userStatus");
  const { user, getAccessTokenSilently } = useAuth0();
  const [isSidebar, setIsSidebar] = useState(true);
  const [theme, colorMode] = useMode();
  const [mobileCollapsed, setMobileCollapsed] = useState(true);

  useEffect(() => {
    const loadUserStatus = async () => {
      const accessToken = await getAccessTokenSilently();
      const resUserStatus = await getUserStatus(accessToken, user.sub);
      setGlobalState("userStatus", resUserStatus.value);
    };
    loadUserStatus();
  }, []);

  const handleClick = () => {
    setMobileCollapsed(!mobileCollapsed);
  };
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {userStatus && <AlertInfo email={user.email} />}
        <div className="app">
          <Sidebar
            isSidebar={isSidebar}
            title={title}
            mobileCollapsed={mobileCollapsed}
            onClick={handleClick}
          />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} onClick={handleClick} />
            {children}
          </main>
        </div>
        <Footer />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default Layout;
