import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Auth0Provider
    domain="workinglivelogin.cgcnetworking.net"
    clientId="0TjgSLxEmJgbC0Lcdnu4FfwnscnSHoyZ"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: ["https://cgcworkinglive.us.auth0.com/api/v2/"],
      scope: "openid profile email offline_access update",
    }}
    /* cacheLocation ="localstorage" */
    
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Auth0Provider>
);
