import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const listaOptionsMax = [
    {value: "Webinars with 500 people", available:true},
    {value: "Unlimited Meeting times", available:true},
    {value: "Unlimited Cloud Storage", available:true},
    {value: "Broadcast to Facebook Live", available:true}
]

const listaOptionsRegular = [
    {value: "Webinars with 500 people", available:true},
    {value: "Unlimited Meeting times", available:true},
    {value: "Unlimited Cloud Storage", available:false},
    {value: "Broadcast to Facebook Live", available:false}
]

const listaOptionsBasic = [
    {value: "Webinars with 500 people", available:true},
    {value: "Unlimited Meeting times", available:true},
    {value: "Unlimited time", available:true},
    {value: "Broadcast to Facebook Live", available:true}
]

export const PlanCardInfo = (props) => {

  return (
    <Box
      sx={{ width: "300px", height: "425px", background: "linear-gradient(19deg, #779F60 22%, #ffffff 0%)"}}
      className={`content-card-plan `}
    >
      <Box className="content-card-plan-box" sx={{background:"linear-gradient(to right, #efefbb, #d4d3dd)"}}>
        <Typography color="#919194" textAlign="center" variant="p">
            {props.planTitle}
        </Typography>
        <Typography variant="h3" fontWeight="bold" color={"#6d6027"}>
            {props.planName}
        </Typography>
        <Typography color="#919194" textAlign="center" variant="p">
            {props.aditionalInfo}
        </Typography>
      </Box>
      {props.max && (
        <Box className="content-card-plan-items">
            {listaOptionsMax?.map((val, index)=>{
                return (
                    <Typography
                        textAlign="center"
                        variant="p"
                        display="flex"
                        gap="8px"
                        alignItems="center"
                        fontSize="1rem"
                        color={val?.available?"#88609F":"#C9C9C9"}
                        width="300px"
                    >
                        <CheckIcon fontSize="large" sx={{ color: val?.available?"#88609F":"#C9C9C9"}}></CheckIcon>
                        {val?.value}
                    </Typography>
                )
            })}
        </Box>
      )}
      {props.regular && (
        <Box className="content-card-plan-items">
        {listaOptionsRegular?.map((val, index)=>{
            return (
                <Typography
                    textAlign="center"
                    variant="p"
                    display="flex"
                    gap="8px"
                    alignItems="center"
                    fontSize="1rem"
                    color={val?.available?"#88609F":"#C9C9C9"}
                    width="300px"
                >
                    <CheckIcon fontSize="large" sx={{ color: val?.available?"#88609F":"#C9C9C9"}}></CheckIcon>
                    {val?.value}
                </Typography>
            )
        })}
    </Box>
      )}
      {props.basic && (
        <Box className="content-card-plan-items">
        {listaOptionsBasic?.map((val, index)=>{
            return (
                <Typography
                    textAlign="center"
                    variant="p"
                    display="flex"
                    gap="8px"
                    alignItems="center"
                    fontSize="1rem"
                    color={val?.available?"#88609F":"#C9C9C9"}
                    width="300px"
                >
                    <CheckIcon fontSize="large" sx={{ color: val?.available?"#88609F":"#C9C9C9"}}></CheckIcon>
                    {val?.value}
                </Typography>
            )
        })}
    </Box>
      )}
      
    </Box>
  );
};
