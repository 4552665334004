import { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SlowMotionVideoOutlinedIcon from "@mui/icons-material/SlowMotionVideoOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useAuth0 } from "@auth0/auth0-react";
import { isMobile } from "react-device-detect";
import * as React from "react";
import { useGlobalState } from "../../hooks/state";
import logo from "../../resources/CGC-logo-white-r.webp"

const Item = ({ title, to, icon, selected, setSelected, onClick, disabled=false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: disabled?"#808080": colors.grey[100],
      }}
      onClick={() => {
        if (title == "Logout") {
          onClick();
        } else {
          setSelected(title);
        }
      }}      
      icon={icon}
    >
      <Typography  variant="h6">{title}</Typography>
      {!disabled && <Link  to={to} />}
    </MenuItem>
  );
};

const Sidebar = (props) => {
  const [userStatus] = useGlobalState("userStatus");
  const { user, logout } = useAuth0();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  useEffect(() => {
    if (isMobile) {
      setIsCollapsed(true);
    }
  }, []);

  return (
    <Box
      className={
        isMobile
          ? props.mobileCollapsed
            ? "slider-mobile-version"
            : "slider-mobile-version slider-mobile-version-open"
          : ""
      }
      sx={{
        "& .pro-sidebar-inner": {
          background: "#88609F !important",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 20px !important",
          color:"white",
        },
        "& .pro-inner-item:hover": {
          color: "#7dc951 !important",
          fontWeight: "bold",
        },
        "& .pro-menu-item.active": {
          color: "#0D0D0D !important",
          fontWeight: "bold",
        },
      }}
    >
      <ProSidebar collapsed={isMobile ? props.mobileCollapsed : isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          {!isMobile && (
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
                margin: "5px 0px",
                color: colors.grey[100],
              }}
            >
              {!isCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="0px"
                >
                  <img
                    src={logo}
                    alt="CGC Logo"
                    width="170px"
                    className="logo-image-dashboard"
                  />
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon fontSize="small" />
                  </IconButton>
                </Box>
              )}
            </MenuItem>
          )}
          {isMobile && (
            <MenuItem
              onClick={props.onClick}
              icon={props.mobileCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
                margin: "5px 0px",
                color: colors.grey[100],
              }}
            >
              {!props.mobileCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="0px"
                >
                  <img
                    src={logo}
                    alt="CGC Logo"
                    width="110px"
                    className="logo-image-dashboard"
                  />
                  <IconButton onClick={props.onClick}>
                    <CloseOutlinedIcon sx={{ fontSize: "30px !important", color:"white" }} />
                  </IconButton>
                </Box>
              )}
            </MenuItem>
          )}

          {!props.mobileCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`${user?.picture}`}
                  style={{ cursor: "pointer", borderRadius: "50%",border:"2px solid white" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h4"
                  color={"white"}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {user?.nickname}
                </Typography>
              </Box>
            </Box>
          )}
          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img                
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`${user?.picture}`}
                  style={{ cursor: "pointer", borderRadius: "50%" ,border:"2px solid white"}}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h4"
                  color="white"
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {user?.nickname}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "5px"}>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon fontSize="medium" />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="My Profile"
              to="/profile"
              icon={<AccountCircleOutlinedIcon fontSize="medium" />}
              selected={selected}
              setSelected={setSelected}
            />
            {!userStatus &&
              <Item
                title="Zoom Settings"
                to="/settings"
                icon={<SettingsOutlinedIcon fontSize="medium" />}
                selected={selected}
                disabled={userStatus}
                setSelected={setSelected}
              />
            }
            {!userStatus &&
              <Item
                title="Zoom Recording"
                to="/recordings"
                icon={<SlowMotionVideoOutlinedIcon fontSize="medium" />}
                selected={selected}
                disabled={userStatus}
                setSelected={setSelected}
              />
            }
            <Item
              title="Logout"
              onClick={(e)=>{logout({ logoutParams: { returnTo: window.location.origin } })}}
              icon={<LogoutOutlinedIcon fontSize="medium" />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Data
            </Typography>
            <Item
              title="Manage Team"
              to="/team"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Contacts Information"
              to="/contacts"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Invoices Balances"
              to="/invoices"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Pages
            </Typography>
            <Item
              title="Profile Form"
              to="/form"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Calendar"
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="FAQ Page"
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Charts
            </Typography>
            <Item
              title="Bar Chart"
              to="/bar"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Pie Chart"
              to="/pie"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Line Chart"
              to="/line"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Geography Chart"
              to="/geography"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
