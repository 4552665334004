const updatePicture = async (token, payload, sub) => {
  const options = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  };

  const reqUpdate = await fetch(
    `https://cgcworkinglive.us.auth0.com/api/v2/users/${sub}`,
    options
  );
  const resUpdate = await reqUpdate.json();

  if (!reqUpdate.ok) {
    console.log("Error");
    return { error: "Error Updating Picture" };
  }
  if (reqUpdate.ok) {
    console.log(resUpdate);
    return { data: resUpdate };
  }
};

export default updatePicture;
