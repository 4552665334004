const userInfo = async (id, token) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      user_id:id,
      comp: process.env.REACT_APP_COMP,
    },
  };

  const reqInfo = await fetch(
    "https://companduser-develop.herokuapp.com/user",
    options
  );

  const resInfo = await reqInfo.json();

  if (!reqInfo.ok) {
    return { error: "Error getting the user Info" };
  }

  if (reqInfo.ok) {
    return { data: resInfo };
  }
};

export default userInfo;
