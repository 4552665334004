import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import Modal from "react-modal";

Modal.setAppElement("#root");

const ShareMeeting = () => {
  const [openModal, setOpenModal] = useState(false);
  const [textFeedBack, setTextFeedback] = useState("");

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const shareInvitation = async () => {
    const shareData = {
      title: "MDN",
      text: "Learn web development on MDN!",
      url: "https://developer.mozilla.org",
    };

    // Share must be triggered by "user activation"
    try {
      await navigator.share(shareData);
      setTextFeedback("MDN shared successfully");
    } catch (err) {
      setTextFeedback(`Error: ${err}`);
    }
  };

  return (
    <>
      <Box>
        <Button className="open-presentation-zrp" onClick={shareInvitation}>
          Invite
        </Button>
      </Box>
      {/*  Invite  */}
      <div className="App">
        <Modal
          isOpen={openModal}
          // onRequestClose={() => setConfirmModal(false)}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max-invite",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          <button
            className="close-modal"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button>
        </Modal>
      </div>
    </>
  );
};

export default ShareMeeting;
