const getProducts = async (publicToken) => {
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${publicToken}`,
      "Content-Type": "application/json",
    },
  };

  const reqProducts = await fetch(
    "https://productssellwithzoom.herokuapp.com/products?storeId=52433035&page_size=30&page_number=1",
    options
  );

  const resProducts = await reqProducts.json();

  if (!reqProducts.ok) {
    return { error: "Error getting Products" };
  }

  if (reqProducts.ok) {
    return { data: resProducts };
  }
};

export default getProducts;
