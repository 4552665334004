import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import ShareMeeting from "../components/shareMeeting";


export default function M6InviteLink({ onhandleNext, newMeetingObj, setNewMeetingObj, setOpen }) {
    const { user, getAccessTokenSilently,  } = useAuth0();
    const [copied, setCopied] = useState(false);
    const [openShareInvite, setopenShareInvite] = useState(false)

    const copyInvitation = (id) => {
        navigator.clipboard
            .writeText(
                `${window.location.origin}/${newMeetingObj?.idMeeting}${newMeetingObj?.url ? `?loadIframe=` + newMeetingObj?.url : ""
                }`
            )
            .then(() => {
                setCopied(true);
            });
    };

    const openShareModal = () => {
        setopenShareInvite(true)
    }

    const activeLink = async () => {
      try {
        const myToken = await getAccessTokenSilently();
        localStorage.setItem("sax", myToken);
        localStorage.setItem("sb", user.sub);

      } catch (error) {
        console.log("e", error)
      }

    };

    const openMeeting = () => {
      activeLink()
        window.open(
            `${window.location.origin}/${newMeetingObj?.idMeeting}${newMeetingObj?.url ? `?loadIframe=` + newMeetingObj?.url : ""
            }`,
            "_blank"
        );
    };

    return (
        <div className="content-form">
            <div>
                <h2
                    style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "#202020",
                    }}
                >
                    Create Invite Link
                </h2>
            </div>
            <div className="content-form">
                <Box className="content-form-header">
                    <Typography variant="h3" fontWeight="bold" textAlign="center">
                        {newMeetingObj?.topic}
                    </Typography>
                    <Typography variant="h6" fontWeight="bold">
                        Your Zoom Shooping meeting has been created!{" "}
                    </Typography>
                </Box>
                <Box className="shared-zoom-meeting"></Box>
                <Box className="form-inline">
                    <Box className="form-inline-link">
                        {newMeetingObj?.idMeeting && (
                            <>
                                <input
                                    type="text"
                                    className="input-credentials share-meeting shared-meeting-input"
                                    id="txtLinkMeeting"
                                    disabled={true}
                                    placeholder={`${window.location.origin}/${newMeetingObj?.idMeeting}${newMeetingObj?.url ? `?loadIframe=` + newMeetingObj?.url : ""
                                        }`}
                                />
                                <Button
                                    className="start-meeting-tab btn-width-start"
                                    onClick={(e) => {
                                        copyInvitation();
                                    }}
                                >
                                    {copied ? "Copied" : "COPY LINK"}
                                </Button>
                            </>
                        )}
                        {!newMeetingObj?.idMeeting && (
                            <Skeleton variant="rounded" width="100%" height={30} />
                        )}
                    </Box>
                </Box>
                {/* this is for co-host */}
                {/* <Box className="form-inline form-inline-container">
                <Box className="form-inline-link">
                  {!loading && (
                    <>
                      <input
                        type="text"
                        className="input-credentials share-meeting shared-meeting-input"
                        id="txtLinkMeeting"
                        disabled={true}
                        placeholder={`${window.location.origin}/${idMeeting}${
                          url ? `?loadIframe=` + url + "" : "?rep=nacybaker"
                        }`}
                      />
                      <Button
                        className="btn-width-start-co"
                        onClick={(e) => {
                          navigator.clipboard
                            .writeText(
                              `${window.location.origin}/${idMeeting}${
                                url ? `?loadIframe=` + url : "?rep=nacybaker"
                              }`
                            )
                            .then(() => {
                              setCopyFirst(true);
                              // console.log("Text copied to clipboard...");
                            });
                        }}
                      >
                        {copyFirst ? "COPIED" : "COPY CO-HOST LINK"}
                      </Button>
                    </>
                  )}
                  {loading && (
                    <Skeleton variant="rounded" width="100%" height={30} />
                  )}
                </Box>
                <Box className="form-inline-link">
                  {!loading && (
                    <>
                      <input
                        type="text"
                        className="input-credentials share-meeting shared-meeting-input"
                        id="txtLinkMeeting"
                        disabled={true}
                        placeholder={`${window.location.origin}/${idMeeting}${
                          url ? `?loadIframe=` + url : "?rep=tomreev"
                        }`}
                      />
                      <Button
                        className="btn-width-start-co"
                        onClick={(e) => {
                          navigator.clipboard
                            .writeText(
                              `${window.location.origin}/${idMeeting}${
                                url ? `?loadIframe=` + url : "?rep=nacybaker"
                              }`
                            )
                            .then(() => {
                              setCopySecond(true);
                              // console.log("Text copied to clipboard...");
                            });
                        }}
                      >
                        {copySecond ? "COPIED" : "COPY CO-HOST LINK"}
                      </Button>
                    </>
                  )}
                  {loading && (
                    <Skeleton variant="rounded" width="100%" height={30} />
                  )}
                </Box>
                <Box className="form-inline-link">
                  {!loading && (
                    <>
                      <input
                        type="text"
                        className="input-credentials share-meeting shared-meeting-input"
                        id="txtLinkMeeting"
                        disabled={true}
                        placeholder={`${window.location.origin}/${idMeeting}${
                          url ? `?loadIframe=` + url : "?rep=terryroan"
                        }`}
                      />
                      <Button
                        className="btn-width-start-co"
                        onClick={(e) => {
                          navigator.clipboard
                            .writeText(
                              `${window.location.origin}/${idMeeting}${
                                url ? `?loadIframe=` + url : "?rep=nacybaker"
                              }`
                            )
                            .then(() => {
                              setCopyThird(true);
                              // console.log("Text copied to clipboard...");
                            });
                        }}
                      >
                        {copyThird ? "COPIED" : "COPY CO-HOST LINK"}
                      </Button>
                    </>
                  )}
                  {loading && (
                    <Skeleton variant="rounded" width="100%" height={30} />
                  )}
                </Box>
              </Box> */}
                <div className="content-btn-down">
                    <Button
                        className="btn-width-start-exit"
                        onClick={(e) => {
                            openShareModal();
                        }}
                    >
                        SHARE INVITE
                    </Button>
                    <Button
                        className="btn-width-start-exit"
                        onClick={(e) => {
                            setOpen(false);
                            window.location.reload();
                        }}
                    >
                        GO TO DASHBOARD
                    </Button>
                    <Button
                        className="start-meeting-tab btn-width-start"
                        onClick={(e) => {
                            openMeeting();
                        }}
                    >
                        START MEETING NOW
                    </Button>
                </div>
            </div>
            {openShareInvite&& newMeetingObj?.idMeeting &&
              <ShareMeeting openModal={openShareModal} setopenModal={setopenShareInvite} meetingID={newMeetingObj?.idMeeting}/>}
        </div>
    )
}
